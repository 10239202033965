<template>
  <v-row justify="space-around">
    <v-col cols="auto">
      <v-dialog
          transition="dialog-top-transition"
          max-width="600"
          v-model="dialogs"
      >

        <v-card>
          <v-toolbar
              color="primary"
              dark
          >Добавить представителя
          </v-toolbar>
          <v-card-text>
            <div class="mt-5 ml-10 mr-5 mb-5">
              <v-text-field
                  label="Фамилия"
                  hide-details="auto"
                  v-model="representative.surname"
              ></v-text-field>
              <v-text-field
                  label="Имя"
                  v-model="representative.name"
                  hide-details="auto"
              ></v-text-field>
              <v-text-field
                  label="Отчество"
                  v-model="representative.middleName"
                  hide-details="auto"
              ></v-text-field>
              <v-text-field
                  label="Отношение"
                  v-model="representative.relations"
                  hide-details="auto"
              ></v-text-field>
              <v-dialog
                  ref="dialog"
                  v-model="modal"
                  :return-value.sync="representative.birthday"
                  persistent
                  width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field class="mt-4"
                                v-model="representative.birthday"
                                label="Дата рождения"
                                prepend-icon="event"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="representative.birthday"
                    scrollable
                    locale="ru-RU"
                    first-day-of-week=1
                >
                  <v-spacer></v-spacer>
                  <v-btn
                      text
                      color="primary"
                      @click="modal = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                      text
                      color="primary"
                      @click="$refs.dialog.save(representative.birthday)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-dialog>
              <v-text-field-integer
                  v-model="phone"
                  label="Телефон *"
                  v-bind:properties="{
                        readonly: false,
                        outlined: false,
                        clearable: true,
                        placeholder: '',
                      }"
                  v-bind:options="{
                        inputMask: '#(###)###-##-##',
                        outputMask: '###########',
                        empty: null,
                        applyAfter: false,
                      }"
              >
              </v-text-field-integer>
              <v-radio-group
                  v-model="representative.male"
                  row
              >
                <v-radio
                    label="М"
                    value="MAN"
                ></v-radio>
                <v-radio
                    label="Ж"
                    value="WOMAN"
                ></v-radio>
              </v-radio-group>
            </div>
            <div class="mt-5 ml-10 mr-5 mb-5">
              <v-text-field
                  label="Область"
                  v-model="representative.region"
                  hide-details="auto"
              ></v-text-field>
              <v-text-field
                  label="Город"
                  v-model="representative.city"
                  hide-details="auto"
              ></v-text-field>
              <v-textarea class="mt-3"
                          name="input-7-1"
                          label="Адрес"
                          rows="3"
                          v-model="representative.address"
                          hint="Введите адрес"
              ></v-textarea>
            </div>
          </v-card-text>
          <v-card-actions class="justify-end">

            <v-spacer></v-spacer>
            <v-btn
                text
                @click="closeDialog()"
            >Выйти
            </v-btn>
            <v-btn
                text
                dark
                color="primary"
                @click="saveRepresent()"
            >Сохранить
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import loader from "../../../mixins/loader";

export default {
  name: "NewRepresentative",
  props: {
    dialog: null,
    residentId: null,
  },
  data() {
    return {
      modal: false,
      phone: null,
      representative: {
        surname: "",
        name: "",
        middleName: "",
        relations: "",
        birthday: "",
        male: "MAN",
        region: "",
        city: "",
        address: "",
        phones:[],
      },
    }
  },
  watch:{
    phone() {
      if (this.phone != null && this.phone.trim() != "") {
        this.representative.phones = [];
        this.representative.phones.push({
          phone: this.phone,
          type: "REPRESENT"
        })
      }
    },
  },
  computed: {
    dialogs: {
      get: function () {
        return this.dialog;
      },
      // сеттер:
      set: function () {
        this.$emit("closeDialog")
      }
    }
  },
  mixins: [loader],
  methods: {
    closeDialog() {
      this.$emit("refreshCard")
      this.$emit("closeDialog")
    },
    saveRepresent() {
      this.httpRequest(
          this.$api.resident.addRepresentative,{
            id: this.residentId,
            representative: this.representative
          },
          () => {
            this.$emit("refreshCard")
            this.$emit("closeDialog")
          }
      )
    },
  },
}
</script>

<style scoped>

</style>
