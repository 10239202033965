export default function (instance){
    return {
        getAll(){
            return instance.get('phones')
        },
        getOne(id){
            return instance.get(`phones/${id}`)
        },
        save(phone){
            return instance.post(`phones/${phone.id}`, phone)
        },
        update(phone){
            return instance.put(`phones/${phone.id}`, phone)
        },
        deleted(id){
            return instance.delete(`phones/${id}`)
        },
    }
}