

export default function (instance){
    return {
        signIn(payload){
            return  instance.post('auth/login', payload)
                .then((response) =>{
                    localStorage.setItem("Authorization", response.data.token)
                })
        },
        signUp(payload){
            return instance.post('auth/sign-up', payload)
        },
        auth(payload){
            return instance.post('auth/auth', payload)
        },
        logout(){

        },
    }
}
