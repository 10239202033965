<template>
  <div>
    <v-container>
      <v-row class="mt-4">
        <v-btn
            color="primary"
            @click="add()"
        >Добавить
        </v-btn>
      </v-row>
    </v-container>

    <v-data-table
        :headers="headers"
        :items="users"
        :items-per-page="15"
        class="elevation-1"
        :footer-props="{
            itemsPerPageText:'Элементов на странице',
        }"
    >
      <template v-slot:item.link="{ item }">
        <v-btn icon @click="userEdit(item)">
          <v-icon >settings</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import loader from "../../mixins/loader";

export default {
  name: "UsersList",
  mixins: [loader],
  props: {
    status: String
  },
  data() {
    return {
      users: [],
      headers: [
        {text: 'Фамилия', align: 'start', sortable: true, value: 'surname',},
        {text: 'Имя', align: 'start', sortable: true, value: 'name',},
        {text: 'Отчество', align: 'start', sortable: true, value: 'middleName',},
        {text: 'Email', align: 'start', sortable: true, value: 'email',},
        {text: 'Телефон', align: 'start', sortable: true, value: 'phone',},
        {text: '', align: 'start', sortable: true, value: 'link',},
      ],
    }
  },
  methods: {
    add(){
      this.$store.commit('users/SET_EDIT_USER',{
        id: null,
        username: '',
        email: '',
        name: '',
        middleName: '',
        surname: '',
        password: '',
        phone: '',
        status: 'ACTIVE',
        role: [],
      })
      this.$router.push('user-card')
    },

    userEdit(user){
      this.$store.commit('users/SET_EDIT_USER',user)
      this.$router.push('user-card')
    },
    getUsers() {
      if (this.status === "ACTIVE") {
        this.httpRequest(
            this.$api.users.getAll,
            null,
            (response) => {
              this.users = response.data
            }
        )
      }
      if (this.status === "DELETED") {
        this.httpRequest(
            this.$api.users.getAllDeleted,
            null,
            (response) => {
              this.users = response.data
            }
        )
      }
    },
  },
  mounted() {
    this.getUsers()
  }
}
</script>

<style scoped>

</style>
