<template>
  <v-alert
      border="left"
      dismissible
      :type="message.type"
  >
    {{ message.text }}
  </v-alert>
</template>

<script>
export default {
  name: "Alert",
  props:{
    message: Object
  },
  created() {
    setInterval(() => this.$store.dispatch("message/deletedItemMessageTop", this.message) , 2000);
  }
}
</script>

<style scoped>

</style>
