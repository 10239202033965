
export default {
    namespaced: true,

    state: {
        medicalCard:null,
        searchRequest:{
            birthday: null,
            orderDateStart: null,
            orderDateEnd: null,
            hospitalDateStart: null,
            hospitalDateEnd: null,
            id: null,
            surname: null,
            name: null,
            middleName: null,
            male: null,
            phone: null,
            responsible: [],
            chronic: [],
            service: [],
            comment: null,
            agreement: null,
            responsibleService: [],
            serviceDateStart: null,
            serviceDateEnd: null,
            city: null,
            address: null,
        },
    },
    mutations: {
        SET_MEDICAL_CARD(state, medicalCard) {
            state.medicalCard = medicalCard;
        },

        DELETE_MEDICAL_CARD(state) {
            state.medicalCard = null;
        },

        CLEAR_SEARCH_MEDICAL_CARD(state) {
            state.searchRequest ={
                birthday: null,
                orderDateStart: null,
                orderDateEnd: null,
                hospitalDateStart: null,
                hospitalDateEnd: null,
                id: null,
                surname: null,
                name: null,
                middleName: null,
                male: null,
                phone: null,
                responsible: [],
                chronic: [],
                service: [],
                comment: null,
                agreement: null,
                responsibleService: [],
                serviceDateStart: null,
                serviceDateEnd: null,
                city: null,
                address: null,
            };
        },
        SET_SEARCH_BIRTHDATE(state, birthday) {
            state.searchRequest.birthday = birthday;
        },
        SET_SEARCH_ORDER_DATE_START(state, orderDateStart) {
            state.searchRequest.orderDateStart = orderDateStart;
        },
        SET_SEARCH_ORDER_DATE_END(state, orderDateEnd) {
            state.searchRequest.orderDateEnd = orderDateEnd;
        },
        SET_SEARCH_HOSPITAL_DATE_START(state, hospitalDateStart) {
            state.searchRequest.hospitalDateStart = hospitalDateStart;
        },
        SET_SEARCH_HOSPITAL_DATE_END(state, hospitalDateEnd) {
            state.searchRequest.hospitalDateEnd = hospitalDateEnd;
        },
        SET_SEARCH_RESPONSIBLE(state, responsible) {
            state.searchRequest.responsible = responsible;
        },
        SET_SEARCH_NAME(state, name) {
            state.searchRequest.name = name;
        },
        SET_SEARCH_SURNAME(state, surname) {
            state.searchRequest.surname = surname;
        },
        SET_SEARCH_ID(state, id) {
            state.searchRequest.id = id;
        },
        SET_SEARCH_MIDDLE_NAME(state, middleName) {
            state.searchRequest.middleName = middleName;
        },
        SET_SEARCH_MALE(state, male) {
            state.searchRequest.male = male;
        },
        SET_SEARCH_PHONE(state, phone) {
            state.searchRequest.phone = phone;
        },
        SET_SEARCH_COMMENT(state, comment) {
            state.searchRequest.comment = comment;
        },
        SET_SEARCH_AGREEMENT(state, agreement) {
            state.searchRequest.agreement = agreement;
        },
        SET_SEARCH_CHRONIC(state, chronic) {
            state.searchRequest.chronic = chronic;
        },
        SET_SEARCH_SERVICE(state, service) {
            state.searchRequest.service = service;
        },
        SET_SEARCH_RESPONSIBLE_SERVICE(state, responsibleService) {
            state.searchRequest.responsibleService = responsibleService;
        },
        SET_SEARCH_SERVICE_DATE_START(state, serviceDateStart) {
            state.searchRequest.serviceDateStart = serviceDateStart;
        },
        SET_SEARCH_SERVICE_DATE_END(state, serviceDateEnd) {
            state.searchRequest.serviceDateEnd = serviceDateEnd;
        },
        SET_SEARCH_CITY(state, city) {
            state.searchRequest.city = city;
        },
        SET_SEARCH_ADDRESS(state, address) {
            state.searchRequest.address = address;
        },
    },
    getters: {
        getMedicalCard(state) {
            return state.medicalCard
        },
        getSearchRequest(state) {
            return state.searchRequest
        },
        getSearchBirthday(state) {
            return state.searchRequest.birthday
        },
        getSearchOrderDateStart(state) {
            return state.searchRequest.orderDateStart
        },
        getSearchOrderDateEnd(state) {
            return state.searchRequest.orderDateEnd
        },
        getSearchHospitalDateStart(state) {
            return state.searchRequest.hospitalDateStart
        },
        getSearchHospitalDateEnd(state) {
            return state.searchRequest.hospitalDateEnd
        },
        getSearchId(state) {
            return state.searchRequest.id
        },
        getSearchSurname(state) {
            return state.searchRequest.surname
        },
        getSearchName(state) {
            return state.searchRequest.name
        },
        getSearchMiddleName(state) {
            return state.searchRequest.middleName
        },
        getSearchMale(state) {
            return state.searchRequest.male
        },
        getSearchPhone(state) {
            return state.searchRequest.phone
        },
        getSearchResponsible(state) {
            return state.searchRequest.responsible
        },
        getSearchChronic(state) {
            return state.searchRequest.chronic
        },
        getSearchService(state) {
            return state.searchRequest.service
        },
        getSearchComment(state) {
            return state.searchRequest.comment
        },
        getSearchAgreement(state) {
            return state.searchRequest.agreement
        },
        getSearchResponsibleService(state) {
            return state.searchRequest.responsibleService
        },
        getSearchServiceDateStart(state) {
            return state.searchRequest.serviceDateStart
        },
        getSearchServiceDateEnd(state) {
            return state.searchRequest.serviceDateEnd
        },
        getSearchCity(state) {
            return state.searchRequest.city
        },
        getSearchAddress(state) {
            return state.searchRequest.address
        },
    },
    actions: {
        async setMedicalCard({commit}, medicalCard){
            commit("SET_MEDICAL_CARD", medicalCard)
        },

        async getMedicalCard({getters}){
            return getters.getMedicalCard
        },

        async setSearchBirthday({commit}, birthday){
            commit("SET_SEARCH_BIRTHDATE", birthday)
        },
        async setSearchOrderDateStart({commit}, orderDateStart){
            commit("SET_SEARCH_ORDER_DATE_START", orderDateStart)
        },
        async setSearchOrderDateEnd({commit}, orderDateEnd){
            commit("SET_SEARCH_ORDER_DATE_END", orderDateEnd)
        },
        async setSearchHospitalDateStart({commit}, hospitalDateStart){
            commit("SET_SEARCH_HOSPITAL_DATE_START", hospitalDateStart)
        },
        async setSearchHospitalDateEnd({commit}, hospitalDateEnd){
            commit("SET_SEARCH_HOSPITAL_DATE_END", hospitalDateEnd)
        },
        async setSearchResponsible({commit}, responsible){
            commit("SET_SEARCH_RESPONSIBLE", responsible)
        },
        async setSearchName({commit}, name){
            commit("SET_SEARCH_NAME", name)
        },
        async setSearchSurname({commit}, surname){
            commit("SET_SEARCH_SURNAME", surname)
        },
        async setSearchId({commit}, id){
            commit("SET_SEARCH_ID", id)
        },
        async setSearchMiddleName({commit}, middleName){
            commit("SET_SEARCH_MIDDLE_NAME", middleName)
        },
        async setSearchMale({commit}, male){
            commit("SET_SEARCH_MALE", male)
        },
        async setSearchPhone({commit}, phone){
            commit("SET_SEARCH_PHONE", phone)
        },
        async setSearchComment({commit}, comment){
            commit("SET_SEARCH_COMMENT", comment)
        },
        async setSearchAgreement({commit}, agreement){
            commit("SET_SEARCH_AGREEMENT", agreement)
        },
        async setSearchChronic({commit}, chronic){
            commit("SET_SEARCH_CHRONIC", chronic)
        },
        async setSearchService({commit}, service){
            commit("SET_SEARCH_SERVICE", service)
        },
        async setSearchResponsibleService({commit}, responsibleService){
            commit("SET_SEARCH_RESPONSIBLE_SERVICE", responsibleService)
        },
        async setSearchServiceDateStart({commit}, serviceDateStart){
            commit("SET_SEARCH_SERVICE_DATE_START", serviceDateStart)
        },
        async setSearchServiceDateEnd({commit}, serviceDateStart){
            commit("SET_SEARCH_SERVICE_DATE_END", serviceDateStart)
        },
        async setSearchCity({commit}, city){
            commit("SET_SEARCH_CITY", city)
        },
        async setSearchAddress({commit}, address){
            commit("SET_SEARCH_ADDRESS", address)
        },
        async clearSearch({commit}){
            commit("CLEAR_SEARCH_MEDICAL_CARD")
        },
    },
}
