import Vue from 'vue'
import Vuex from 'vuex'
import users from "@/store/modules/users";
import medicalCards from "../store/modules/medicalCards";
import message from "../store/modules/message";
import settings_themes from "@/store/modules/settings_themes";
import VueLocalStorage from 'vue-localstorage'

Vue.use(Vuex)
Vue.use(VueLocalStorage)

export default new Vuex.Store({
  modules: {
    settings_themes,
    users,
    medicalCards,
    message,
  }
})
