<template>
  <v-container>
    <v-row>
      <v-col
          cols="12"
          md="4"
      >
        <v-text-field
            v-model="user.name"
            :counter="10"
            label="Имя"
            required
        ></v-text-field>
      </v-col>

      <v-col
          cols="12"
          md="4"
      >
        <v-text-field
            v-model="user.middleName"
            :counter="10"
            label="Отчество"
            required
        ></v-text-field>
      </v-col>

      <v-col
          cols="12"
          md="4"
      >
        <v-text-field
            v-model="user.surname"
            :counter="10"
            label="Фамилия"
            required
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col
          cols="12"
          md="4"
      >
        <v-text-field
            v-model="user.email"
            label="E-mail"
            required
        ></v-text-field>
      </v-col>
      <v-col
          cols="12"
          md="4"
      >
        <v-text-field-integer
            v-model="user.phone"
            label="Телефон"
            required
            v-bind:properties="{
              readonly: false,
              disabled: false,
              outlined: false,
              clearable: true,
              placeholder: '',
            }"
            v-bind:options="{
              inputMask: '#(###)###-##-##',
              outputMask: '###########',
              empty: null,
              applyAfter: false,
            }"
        ></v-text-field-integer>
      </v-col>
      <v-col
          cols="12"
          md="4"
      >
        <v-select
            v-model="user.role"
            :items="roleAll"
            label="Роль"
            multiple
            persistent-hint
            return-object
            item-text="description"
            item-value="id"
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col
          cols="12"
          md="4"
      >
        <v-text-field
            v-model="user.password"
            label="Пароль"
            required
        ></v-text-field>
      </v-col>
      <v-col
          cols="12"
          md="4"
      >
        <v-text-field
            v-model="user.position"
            :counter="10"
            label="Должность"
            required
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-checkbox
          v-model="checkbox"
          :label="labelCheckbox"
      ></v-checkbox>
    </v-row>
    <v-row>
      <v-btn
          color="primary"
          @click="save()"
      >Сохранить
      </v-btn>
    </v-row>
  </v-container>
</template>

<script>
import loader from "../../mixins/loader";

export default {
  name: "UserCard",
  mixins: [loader],

  data() {
    return {
      // mask: '##:###',
      checkbox: true,
      user: {
        id: null,
        username: '',
        email: '',
        name: '',
        middleName: '',
        surname: '',
        password: '',
        phone: '',
        position: '',
        status: 'ACTIVE',
        role: [],
      },
      roleAll: [],
    }
  },
  watch: {
    checkbox() {
      if (this.checkbox) {
        this.user.status = "ACTIVE"
      } else {
        this.user.status = "DELETED"
      }
    }
  },
  computed: {
    labelCheckbox() {
      return this.user.status === "ACTIVE" ? "Работает" : "Удален"
    }
  },
  methods: {
    getRole() {
      this.httpRequest(
          this.$api.roles.getAll,
          null,
          (response) => {
            this.roleAll = response.data
          }
      )
    },
    save() {
      this.httpRequest(
          this.$api.users.save,
          this.user,
          async () => {
            this.$store.commit('users/DELETE_EDIT_USER')
            this.$store.dispatch("message/setMessageTop",{
              type: "success",
              text: "Пользователь сохранен"
            })
            await this.$router.push("users-search")
          }
      )
    }
  },
  async created() {
    this.user = await this.$store.dispatch("users/getUserEdit")
    if (!this.user) {
      await this.$router.push("users-search")
    }
    this.checkbox = this.user.status === "ACTIVE"
    this.getRole()
  }
}
</script>

<style scoped>

</style>
