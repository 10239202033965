<template>
  <v-row justify="space-around">
    <v-col cols="auto">
      <v-dialog
          transition="dialog-top-transition"
          max-width="600"
          v-model="dialogs"
      >

          <v-card>
            <v-toolbar
                color="primary"
                dark
            >Добавить ответственного
            </v-toolbar>
            <v-card-text>
              <v-autocomplete
                  v-model="responsible"
                  :items="responsibleAll"
                  label="Filled"
                  return-object
                  :item-text="getName"
                  item-value="id"
              ></v-autocomplete>

            </v-card-text>
            <v-card-actions class="justify-end">

              <v-spacer></v-spacer>
              <v-btn
                  text
                  @click="closeDialog()"
              >Выйти
              </v-btn>
              <v-btn
                  text
                  dark
                  color="primary"
                  :disabled="disableButton()"
                  @click="addResponsible()"
              >Сохранить
              </v-btn>
            </v-card-actions>
          </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import loader from "../../../mixins/loader";

export default {
  name: "EditPhones",
  props:{
    dialog: null,
    responsibleAll: null,
    medicalCardId: null,
  },
  data() {
    return {
      responsible: {}
    }
  },
  computed:{
    dialogs:{
      get: function () {
        return this.dialog;
      },
      // сеттер:
      set: function () {
        this.$emit("closeDialog")
      }
    }
  },
  mixins: [loader],
  methods:{
    disableButton(){
      if (this.responsible?.id == undefined){
        return true;
      }
      return false
    },
    getName(item){
      let userName = item.surname
      if (item.name && item.middleName){
        userName += " " + item.name[0] + "." +  item.middleName[0]
      }
      if (item.position){
        userName += " (" + item.position + ")"
      }
      return  userName
    },
    closeDialog(){
      this.$emit("refreshCard")
      this.$emit("closeDialog")
    },
    addResponsible(){
      this.httpRequest(
          this.$api.medicalCard.addResponsible,
          {
            id: this.medicalCardId,
            responsible: this.responsible
          },
          () => {
            this.$emit("refreshCard")
            this.$emit("closeDialog")
          }
      )
    },
  }
}
</script>

<style scoped>

</style>
