export default function (instance){
    return {
        getAll(){
            return instance.get('chronic-illness')
        },
        getOne(id){
            return instance.get(`chronic-illness/${id}`)
        },
        save(chronicIllness){
            return instance.post(`chronic-illness/${chronicIllness.id}`, chronicIllness)
        },
        update(chronicIllness){
            return instance.put(`chronic-illness/${chronicIllness.id}`, chronicIllness)
        },
        deleted(id){
            return instance.delete(`chronic-illness/${id}`)
        },
    }
}
