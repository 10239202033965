import { render, staticRenderFns } from "./RepresentativePhone.vue?vue&type=template&id=0f0cc873&scoped=true&"
import script from "./RepresentativePhone.vue?vue&type=script&lang=js&"
export * from "./RepresentativePhone.vue?vue&type=script&lang=js&"
import style0 from "./RepresentativePhone.vue?vue&type=style&index=0&id=0f0cc873&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0f0cc873",
  null
  
)

export default component.exports