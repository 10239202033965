<template>
  <div>
    <div class="d-flex justify-center pa-4">
      <b class="pt-1">Комментарии</b>
      <v-btn icon @click="dialog=true">
        <v-icon>add</v-icon>
      </v-btn>
    </div>
    <v-divider></v-divider>
    <div class="pa-4 comment-list-wrap">

      <comment
          v-for="comment in comments"
          :key="comment.id"
          :comment="comment"
          @refreshCard="$emit('refreshCard')"
      ></comment>

    </div>
    <new-comment
        :dialog="dialog"
        :medicalCardId="medicalCardId"
        @closeDialog="dialog = false"
        @refreshComments="$emit('refreshComments')"
    ></new-comment>
  </div>

</template>

<script>
import Comment from "./Comment"
import NewComment from "../dialogs/medicalCard/NewComment";

export default {
  name: "CommentList",
  props: {
    medicalCardId: null,
    comments: [],
  },

  data(){
    return{
      dialog: false
    }
  },
  components: {
    Comment,
    NewComment,
  },

}
</script>

<style scoped>
.comment-list-wrap {
  max-height: 450px;
  overflow: auto;
}
</style>
