<template>
  <div>
    <div><b>Телефоны:</b>
      <v-btn icon @click="dialog=true">
        <v-icon>add</v-icon>
      </v-btn>
    </div>
    <phone v-for="phone in phones"
           :key="phone.id"
           :phone="phone"
           @refreshCard="$emit('refreshCard')"
    ></phone>
    <edit-phones
        :dialog="dialog"
        :phone="phone"
        :residentId="residentId"
        :representId="representId"
        @closeDialog="closeDialog()"
        @refreshCard="$emit('refreshCard')"
        type="NEW_RESIDENT"
    ></edit-phones>
  </div>
</template>

<script>
import Phone from './Phone'
import EditPhones from "../dialogs/medicalCard/EditPhones";

export default {
  name: "PhonesList",
  props: {
    residentId: null,
    representId: null,
    phones: []
  },
  data() {
    return {
      dialog: false,
      phone: {
        phone: "",
        comment: "",
        type: "RESIDENT",
      },
    }
  },
  components: {
    Phone,
    EditPhones,
  },
  methods: {
    // refreshCard() {
    //   this.$emit('refreshCard')
    // },
    closeDialog() {
      this.dialog = false;
    }
  }
}
</script>

<style scoped>

</style>