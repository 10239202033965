export default function (instance){
    return {
        getAll(){
            return instance.get('residents')
        },
        getOne(id){
            return instance.get(`residents/${id}`)
        },
        getPhone(id){
            return instance.get(`residents/${id}/phones`)
        },
        save(resident){
            return instance.post(`residents/${resident.id}`, resident)
        },
        update(resident){
            return instance.put(`residents/${resident.id}`, resident)
        },
        addPhone(request){
            return instance.post(`residents/${request.id}/phones`, request.phone)
        },
        addChronicIllness(request){
            return instance.post(`residents/${request.id}/chronic-illness`, request.chronicIllness)
        },
        addRepresentative(request){
            return instance.post(`residents/${request.id}/representatives`, request.representative)
        },
        getChronicIllness(request){
            return instance.get(`residents/${request.id}/chronic-illness`)
        },
        deleteChronicIllness(request){
            return instance.delete(`residents/${request.id}/chronic-illness/${request.chronicIllnessId}`)
        },
        delete(resident){
            return instance.delete(`residents/${resident.id}`)
        },
    }
}
