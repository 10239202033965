<template>
  <div class="d-flex flex-row">
    <div class="cursor"  @click="dialog=true">{{ phone.phone }}</div>
    <edit-phones
      :dialog="dialog"
      :phone="phone"
      @closeDialog="closeDialog()"
      @refreshCard="$emit('refreshCard')"
    ></edit-phones>
  </div>
</template>

<script>
import EditPhones from "../dialogs/medicalCard/EditPhones";

export default {
  name: "Phone",
  components: {
    EditPhones,
  },
  props: {
    phone: null
  },
  data() {
    return {
      dialog: false
    }
  },
  methods:{
    closeDialog(){
      this.dialog = false;
    }
  }
}
</script>

<style scoped>
.cursor{
  cursor: pointer;
}
</style>