<template>
  <v-app id="sandbox">
    <v-navigation-drawer
        v-model="settings_primary_drawer_model"
        :permanent="settings_primary_drawer_type === 'permanent'"
        :temporary="settings_primary_drawer_type === 'temporary'"
        :clipped="settings_primary_drawer_clipped"
        :floating="settings_primary_drawer_floating"
        :mini-variant="settings_primary_drawer_mini"
        overflow
        app
    >

      <v-list dark color="#015188" class="navigation-drawer-top-list">
        <v-list-item class="navigation-drawer-top-item">
          <v-list-item-icon>
            <v-icon>account_balance</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="title">

            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>

      <v-list>
        <v-list-item
            v-for="(item, i) in nav_items"
            :key="i"
            link
            :to="item.url"
        >

          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.text }}</v-list-item-title>
          </v-list-item-content>

        </v-list-item>
        <v-list-item
            link
            @click="exit"
        >

          <v-list-item-icon>
            <v-icon>exit_to_app</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Выход</v-list-item-title>
          </v-list-item-content>

        </v-list-item>
      </v-list>

      <v-spacer></v-spacer>
      <v-divider></v-divider>
      <v-list>
        <v-list-item
            @click="settings_primary_drawer_mini = !settings_primary_drawer_mini"
        >

          <v-list-item-icon>
            <v-icon v-if="settings_primary_drawer_mini">navigate_next</v-icon>
            <v-icon v-else>navigate_before</v-icon>
          </v-list-item-icon>

        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar :clipped-left="settings_primary_drawer_clipped" app dark color="#015188">
      <alert class="notification" v-for="notification in notificationsTop"
             :key="notification.id"
             :message="notification"
      >
      </alert>
      <v-app-bar-nav-icon
          v-if="settings_primary_drawer_type !== 'permanent'"
          @click.stop="settings_primary_drawer_model = !settings_primary_drawer_model"
      ></v-app-bar-nav-icon>
      <v-toolbar-title>

      </v-toolbar-title>
      <v-spacer></v-spacer>


      <v-btn v-if="taskList.length > 0" icon to="/tasks/task-list">
        <v-badge
            color="green"
            :content="taskList.length"
        >
          <v-icon>fact_check</v-icon>
        </v-badge>
      </v-btn>

      <v-btn icon @click="settings_color_themes = !settings_color_themes">
        <v-icon>wb_incandescent</v-icon>
      </v-btn>
    </v-app-bar>


    <v-main>

      <v-container fluid>
        <router-view/>
      </v-container>
    </v-main>

    <v-footer :inset="settings_footer_inset" app>
      <span class="px-3">&copy; {{ new Date().getFullYear() }}</span>
    </v-footer>

  </v-app>
</template>

<script>
// import Settings from "@/settings/settings_url";
// import {HTTP} from '@/settings/axios'
import Alert from './views/notification/Alert'

export default {
  components: {
    Alert
  },

  data: () => ({
    admin: "ROLE_ADMIN",
    manager: "ROLE_MANGER",
    seniorManager: "ROLE_SENIOR_MANGER",
    guest: false,
    items: [
      // {
      //   icon: "home",
      //   text: "Главная",
      //   url: "/",
      //   admin: true,
      //   manager: false,
      //   seniorManager: false,
      //   guest: true,
      // },
      {
        icon: "note_add",
        text: "Карты",
        url: "/medical-card/medical-card-list",
        admin: true,
        manager: true,
        seniorManager: true,
        guest: false,
      },
      {
        icon: "addchart",
        text: "Заявки",
        url: "/bid/bid-list",
        admin: true,
        manager: true,
        seniorManager: true,
        guest: false,
      },
      {
        icon: "fact_check",
        text: "Задачи",
        url: "/tasks/task-list",
        admin: true,
        manager: true,
        seniorManager: true,
        guest: false,
      },
      {
        icon: "receipt_long",
        text: "Рабочий лог",
        url: "/log/work",
        admin: true,
        seniorManager: true,
        guest: false,
      },
      {
        icon: "event_note",
        text: "Лог Событий",
        url: "/log/event",
        admin: true,
        seniorManager: true,
        guest: false,
      },
      {
        icon: "account_circle",
        text: "Пользователи",
        url: "/users/users-search",
        admin: true,
        seniorManager: false,
        guest: false,
      },

    ],
    nav_items: [],
    taskList: [],
  }),
  watch: {
    items() {
      this.nav_items = []
      this.getItemMenu()
    }
  },
  computed: {
    notificationsTop: {
      get: function () {
        return this.$store.getters["message/getMessageTop"]
      },
    },
    settings_color_themes: {
      get() {
        return this.$store.getters.settings_color_themes;
      },
      set(value) {
        this.$store.commit('update_settings_color_themes', value)
        this.$vuetify.theme.dark = this.$store.getters.settings_color_themes;
      }
    },
    settings_primary_drawer_model: {
      get() {
        return this.$store.getters.settings_primary_drawer_model;
      },
      set(value) {
        this.$store.commit('update_settings_primary_drawer_model', value)
      }
    },
    settings_primary_drawer_type: {
      get() {
        return this.$store.getters.settings_primary_drawer_type;
      },
      set(value) {
        this.$store.commit('update_settings_primary_drawer_type', value)
      }
    },
    settings_primary_drawer_clipped: {
      get() {
        return this.$store.getters.settings_primary_drawer_clipped;
      },
      set(value) {
        this.$store.commit('update_settings_primary_drawer_clipped', value)
      }
    },
    settings_primary_drawer_floating: {
      get() {
        return this.$store.getters.settings_primary_drawer_floating;
      },
      set(value) {
        this.$store.commit('update_settings_primary_drawer_floating', value)
      }
    },
    settings_primary_drawer_mini: {
      get() {
        return this.$store.getters.settings_primary_drawer_mini;
      },
      set(value) {
        this.$store.commit('update_settings_primary_drawer_mini', value)
      }
    },
    settings_footer_inset: {
      get() {
        return this.$store.getters.settings_footer_inset;
      },
      set(value) {
        this.$store.commit('update_settings_footer_inset', value)
      }
    },
  },
  methods: {

    getItemMenu: async function () {
      let itemMenuForUser = this.nav_items
      let admin = this.admin
      let manager = this.manager
      let seniorManager = this.seniorManager
      // let guest = this.guest
      await this.$store.dispatch("users/getAuthUser")
      let store = this.$store;

      this.items.map(async function (item) {
        if (
            (item.admin && (await store.dispatch("users/hasRoleAuthUser", admin))) ||
            (item.manager && (await store.dispatch("users/hasRoleAuthUser", manager))) ||
            (item.seniorManager && (await store.dispatch("users/hasRoleAuthUser", seniorManager)))
        ) {
          itemMenuForUser.push(item)
        }
      })
    },
    async getTask() {
      const user = await this.$store.dispatch("users/getAuthUser")
      this.$api.task.getByUsersAndDates(
          {
            usersId: [user.id,],
            dateStart: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            dateEnd: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
          }
      )
          .then((response) => {
            this.taskList = response.data
          })

    },
    exit: function () {
      localStorage.setItem('Authorization', "")
      document.location.href = '/'
    },
  },
  async created() {

    if (this.$store.getters.settings_color_themes == null) {
      this.$store.commit('update_settings_color_themes', false)
      this.$vuetify.theme.dark = this.$store.getters.settings_color_themes;
    } else {
      this.$vuetify.theme.dark = this.$store.getters.settings_color_themes;
    }

    if ((await this.$store.dispatch("users/getAuthUser")) == null && this.$route.path !== '/login') {
      window.location.replace("/login");
    }

    console.log(localStorage.getItem("Authorization"))


    this.getItemMenu()

    setInterval(this.getTask, 5000)

  }

}
</script>


<style scoped>
.navigation-drawer-top-list {
  padding: 0;
  /*color: #015188;*/
}

.navigation-drawer-top-item {
  height: 64px !important;
  background: rgba(255, 255, 255, 0.08);
}

.notification{
  position: absolute;
  left: 0;
  top: 0;
  z-index: 999;
  min-width: 100%;
}
</style>
