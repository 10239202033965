<template>
  <div>
    <div><b>Телефоны:</b>
      <v-btn icon @click="dialog=true">
        <v-icon>add</v-icon>
      </v-btn>
    </div>
    <representative-phone  v-for="phone in phones"
           :key="phone.id"
           :phone="phone"
           @refreshCard="refreshCard"
    ></representative-phone>
    <edit-representative-phones
        :dialog="dialog"
        :phone="phone"
        :representId="representId"
        @closeDialog="closeDialog()"
        @refreshCard="refreshCard"
        type="NEW_REPRESENT"
    ></edit-representative-phones>
  </div>
</template>

<script>
import RepresentativePhone from './RepresentativePhone'
import EditRepresentativePhones from "../dialogs/medicalCard/EditRepresentativePhones";
import loader from "../../mixins/loader";

export default {
  name: "RepresentativePhonesList",
  props: {
    representId: null
  },
  data() {
    return {
      dialog: false,
      phones: [],
      phone: {
        phone: "",
        comment: "",
        type: "REPRESENT",
      },
    }
  },
  components: {
    RepresentativePhone,
    EditRepresentativePhones,
  },
  mixins: [loader],
  watch:{
    representId(){
      this.getPhones()
    }
  },
  methods: {
    getPhones(){
      this.httpRequest(
          this.$api.representative.getPhones,
          this.representId,
          (response) => {
            this.phones = response.data
          }
      )
    },
    refreshCard() {
      this.getPhones()
    },
    closeDialog() {
      this.dialog = false;
    }
  },
  mounted() {
    this.getPhones()
  }
}
</script>

<style scoped>

</style>
