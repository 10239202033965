<template>
<div>
  <v-card>
    <v-tabs
        v-model="tab"
        align-with-title
    >
      <v-tab>
        активные
      </v-tab>
      <v-tab>
        удаленные
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <v-card flat>
          <user-list status="ACTIVE"></user-list>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <user-list status="DELETED"></user-list>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</div>
</template>

<script>
import UsersList from "./UsersList";

export default {
  name: "usesList",
  components:{
    "user-list": UsersList
  },
  data(){
    return{
      tab: null,
    }
  },
}
</script>

<style scoped>

</style>
