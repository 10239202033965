<template>
  <v-row justify="space-around">
    <v-col cols="auto">
      <v-dialog
          transition="dialog-top-transition"
          max-width="600"
          v-model="dialogs"
      >

        <v-card>
          <v-toolbar
              color="primary"
              dark
          >Добавить услугу
          </v-toolbar>
          <v-card-text>
            <v-row class="ma-4">
              <v-combobox
                  v-model="service"
                  :items="services"
                  label="Добавить"
                  item-text="title"
                  item-value="id"
                  hide-selected
                  persistent-hint
              ></v-combobox>
            </v-row>
            <v-row class="ma-4">
              <v-autocomplete
                  class="mt-4"
                  v-model="responsible"
                  :items="responsibles"
                  return-object
                  label="Исполнитель"
                  :item-text="getUserTitle"
                  item-value="id"
              ></v-autocomplete>
            </v-row>
            <v-row class="ma-4">
              <v-text-field
                  label="Сумма"
                  v-model="price"
                  hide-details="auto"
              ></v-text-field>
            </v-row>
            <v-row class="ma-4">
              <v-dialog
                  ref="dialog"
                  v-model="modal"
                  :return-value.sync="date"
                  persistent
                  width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field class="mt-4"
                                v-model="date"
                                label="Дата"
                                prepend-icon="event"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="date"
                    scrollable
                    :max="getMaxDate()"
                    locale="ru-RU"
                    first-day-of-week=1
                >
                  <v-spacer></v-spacer>
                  <v-btn
                      text
                      color="primary"
                      @click="modal = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                      text
                      color="primary"
                      @click="$refs.dialog.save(date)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-row>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-spacer></v-spacer>
            <v-btn
                text
                @click="closeDialog()"
            >Выйти
            </v-btn>
            <v-btn
                text
                dark
                color="primary"
                :disabled="validForm"
                @click="saveOrder()"
            >Сохранить
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import loader from "../../../mixins/loader";

export default {
  name: "EditOrder",
  props: {
    dialog: null,
    medicalCardId: null,
    services: null,
    orderId: null,
  },
  data() {
    return {
      modal: false,
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      service: null,
      price: 0,
      responsible: null,
      responsibles: [],
    }
  },
  watch:{
    service(){
      if (typeof this.service == 'string'){
        this.addNewService()
      }
    },
    dialog(){
      if (this.dialog){
        this.getOrder();
      }
    },
  },
  computed: {
    dialogs: {
      get: function () {
        return this.dialog;
      },
      // сеттер:
      set: function () {
        this.$emit("closeDialog")
      },
    },
    validForm: function () {
      return this.price == null || this.price < 0 || this.service == null || this.service?.id <= 0
    },
  },
  mixins: [loader],
  methods: {

    getUserTitle(item){
      let userName = item.surname
      if (item.name && item.middleName){
        userName += " " + item.name[0] + "." +  item.middleName[0]
      }
      if (item.position){
        userName += " (" + item.position + ")"
      }
      return  userName
    },
    getMinDate(){
      return (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
    },
    getMaxDate(){
      return (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
    },
    // getServiceText(item) {
    //   return item.title + " - " + item.price + " руб"
    // },
    closeDialog() {
      this.$emit("refreshOrders")
      this.$emit("closeDialog")
    },
    addNewService(){
      this.httpRequest(
          this.$api.service.addNewService,{
            title:  this.service,
          },
          (response) => {
            this.service = response.data
            this.$emit("refreshService")
          },
          () =>{
            this.service = null;
          }
      )
    },
    getOrder(){
      this.httpRequest(
          this.$api.orders.getOne,
          this.orderId,
          (response) => {
            this.date = response.data.date
            this.service = response.data.service
            this.responsible = response.data.responsible
            this.price = response.data.price
          },
          () =>{
            this.$emit("refreshOrders")
            this.$emit("closeDialog")
          }
      )
    },
    saveOrder() {
      this.httpRequest(
          this.$api.orders.save,
          {
              id: this.orderId,
              price: this.price,
              service: this.service,
              responsible: this.responsible,
              date: this.date,
              medicalCardId: this.medicalCardId,
          },
          () => {
            this.service = null
            this.price = 0
            this.$emit("refreshOrders")
            this.$emit("closeDialog")
          }
      )
    },
    getUsers(){
      this.httpRequest(
          this.$api.users.getAll,
          null,
          (response) => {
            this.responsibles = response.data
          }
      )
    },
  },
  mounted() {
    this.getUsers();
  }
}
</script>

<style scoped>

</style>
