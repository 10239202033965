<template>
  <div class="d-flex flex-row">
    <div class="cursor"  @click="dialog=true">{{ phone.phone }}</div>
    <edit-representative-phones
      :dialog="dialog"
      :phone="phone"
      @closeDialog="closeDialog()"
      @refreshCard="$emit('refreshCard')"
    ></edit-representative-phones>
  </div>
</template>

<script>
import EditRepresentativePhones from "../dialogs/medicalCard/EditRepresentativePhones";

export default {
  name: "RepresentativePhone",
  components: {
    EditRepresentativePhones,
  },
  props: {
    phone: null
  },
  data() {
    return {
      dialog: false
    }
  },
  methods:{
    closeDialog(){
      this.dialog = false;
    }
  }
}
</script>

<style scoped>
.cursor{
  cursor: pointer;
}
</style>
