<template>
  <div>
    <div>
      <v-btn style="margin-top: 5px"
             class="mx-2"
             fab
             dark
             absolute
             right
             small
             color="primary"
             @click="newMedicalDialog = true"
      >
        <v-icon>add</v-icon>
      </v-btn>
    </div>
    <v-expansion-panels>
      <v-expansion-panel>
        <v-expansion-panel-header>
          Фильтры
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <div class="filter-wrap">
            <div>
              <div class="row-filter">
                <div class="mr-4">
                  <v-text-field
                      label="№"
                      v-model="id"
                      hide-details="auto"
                  ></v-text-field>
                </div>
                <div class="mr-4">
                  <v-text-field
                      label="Фамилия"
                      v-model="surname"
                      hide-details="auto"
                  ></v-text-field>
                </div>
                <div class="mr-4">
                  <v-text-field
                      label="Имя"
                      v-model="name"
                      hide-details="auto"
                  ></v-text-field>
                </div>
                <div class="mr-4">
                  <v-text-field
                      label="Отчество"
                      v-model="middleName"
                      hide-details="auto"
                  ></v-text-field>
                </div>
                <div class="mr-4" style="margin-top: -12px">
                  <v-dialog
                      ref="dialog"
                      v-model="modal"
                      :return-value.sync="birthday"
                      persistent
                      width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field class="mt-4"
                                    v-model="birthday"
                                    label="Дата рождения"
                                    prepend-icon="event"
                                    clearable
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="birthday"
                        scrollable
                        locale="ru-RU"
                        first-day-of-week=1
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                          text
                          color="primary"
                          @click="modal = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                          text
                          color="primary"
                          @click="$refs.dialog.save(birthday)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </div>
                <div>
                  <v-autocomplete
                      label="Пол"
                      v-model="male"
                      :items="males"
                      clearable
                  ></v-autocomplete>
                </div>

              </div>
              <div class="row-filter">
                <div class="mr-4">
                  <v-text-field
                      label="Телефон"
                      v-model="phone"
                      hide-details="auto"
                  ></v-text-field>
                </div>

                <div class="mr-4">
                  <v-text-field
                      label="Комментарий"
                      v-model="comment"
                      hide-details="auto"
                  ></v-text-field>
                </div>
                <div class="mr-4">
                  <v-autocomplete
                      label="Согласие"
                      v-model="agreement"
                      :items="agreements"
                      clearable
                  ></v-autocomplete>
                </div>
                <div class="mr-4">
                  <v-autocomplete
                      label="Ответственный"
                      v-model="responsible"
                      :items="responsibles"
                      item-value="id"
                      :item-text="getFioR"
                      multiple
                      clearable
                  ></v-autocomplete>
                </div>
                <div class="mr-4">
                  <v-autocomplete
                      label="Хрон заболевания"
                      v-model="chronic"
                      :items="chronics"
                      item-value="id"
                      item-text="title"
                      multiple
                      clearable
                  ></v-autocomplete>
                </div>
                <div>
                  <v-autocomplete
                      label="Услуги"
                      v-model="service"
                      :items="services"
                      item-value="id"
                      item-text="title"
                      multiple
                      clearable
                  ></v-autocomplete>
                </div>
              </div>
              <div class="row-filter">
                <div class="mr-4">
                  <v-autocomplete
                      label="Исполнитель услуги"
                      v-model="responsibleService"
                      :items="responsibles"
                      item-value="id"
                      :item-text="getFioR"
                      multiple
                      clearable
                  ></v-autocomplete>
                </div>
                <div class="mr-4" style="margin-top: -12px">
                  <v-dialog
                      ref="dialogChronicDateStart"
                      v-model="modalChronicDateStart"
                      :return-value.sync="serviceDateStart"
                      persistent
                      width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field class="mt-4"
                                    v-model="serviceDateStart"
                                    label="Услуги с"
                                    prepend-icon="event"
                                    clearable
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="serviceDateStart"
                        scrollable
                        locale="ru-RU"
                        first-day-of-week=1
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                          text
                          color="primary"
                          @click="modalChronicDateStart = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                          text
                          color="primary"
                          @click="$refs.dialogChronicDateStart.save(serviceDateStart)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </div>

                <div class="mr-4" style="margin-top: -12px">
                  <v-dialog
                      ref="dialogChronicDateEnd"
                      v-model="modalChronicDateEnd"
                      :return-value.sync="serviceDateEnd"
                      persistent
                      width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field class="mt-4"
                                    v-model="serviceDateEnd"
                                    label="Услуги по"
                                    prepend-icon="event"
                                    clearable
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="serviceDateEnd"
                        scrollable
                        locale="ru-RU"
                        first-day-of-week=1
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                          text
                          color="primary"
                          @click="modalChronicDateEnd = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                          text
                          color="primary"
                          @click="$refs.dialogChronicDateEnd.save(serviceDateEnd)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </div>
                <div class="mr-4" style="margin-top: -12px">
                  <v-dialog
                      ref="dialogOrderDateStart"
                      v-model="orderDateStartModel"
                      :return-value.sync="orderDateStart"
                      persistent
                      width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field class="mt-4"
                                    v-model="orderDateStart"
                                    label="Дата обращения с"
                                    prepend-icon="event"
                                    clearable
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="orderDateStart"
                        scrollable
                        locale="ru-RU"
                        first-day-of-week=1
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                          text
                          color="primary"
                          @click="orderDateStartModel = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                          text
                          color="primary"
                          @click="$refs.dialogOrderDateStart.save(orderDateStart)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </div>
                <div style="margin-top: -12px">
                  <v-dialog
                      ref="dialogOrderDateEnd"
                      v-model="orderDateEndModal"
                      :return-value.sync="orderDateEnd"
                      persistent
                      width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field class="mt-4"
                                    v-model="orderDateEnd"
                                    label="Дата обращения по"
                                    prepend-icon="event"
                                    clearable
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="orderDateEnd"
                        scrollable
                        locale="ru-RU"
                        first-day-of-week=1
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                          text
                          color="primary"
                          @click="orderDateEndModal = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                          text
                          color="primary"
                          @click="$refs.dialogOrderDateEnd.save(orderDateEnd)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </div>
              </div>
              <div class="row-filter">
                <div class="mr-4">
                  <v-text-field
                      label="Город"
                      v-model="city"
                      hide-details="auto"
                  ></v-text-field>
                </div>
                <div class="mr-4">
                  <v-text-field
                      label="Адрес"
                      v-model="address"
                      hide-details="auto"
                  ></v-text-field>
                </div>
                <div class="mr-4" style="margin-top: -12px">
                  <v-dialog
                      ref="dialogHospitalDateStart"
                      v-model="hospitalDateStartModal"
                      :return-value.sync="hospitalDateStart"
                      persistent
                      width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field class="mt-4"
                                    v-model="hospitalDateStart"
                                    label="Стационар с"
                                    prepend-icon="event"
                                    clearable
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="hospitalDateStart"
                        scrollable
                        locale="ru-RU"
                        first-day-of-week=1
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                          text
                          color="primary"
                          @click="hospitalDateStartModal = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                          text
                          color="primary"
                          @click="$refs.dialogHospitalDateStart.save(hospitalDateStart)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </div>
                <div class="mr-4" style="margin-top: -12px">
                  <v-dialog
                      ref="dialogHospitalDateEnd"
                      v-model="hospitalDateEndModal"
                      :return-value.sync="hospitalDateEnd"
                      persistent
                      width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field class="mt-4"
                                    v-model="hospitalDateEnd"
                                    label="Стационар по"
                                    prepend-icon="event"
                                    clearable
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="hospitalDateEnd"
                        scrollable
                        locale="ru-RU"
                        first-day-of-week=1
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                          text
                          color="primary"
                          @click="hospitalDateEndModal = false"
                      >
                        выход
                      </v-btn>
                      <v-btn
                          text
                          color="primary"
                          @click="$refs.dialogHospitalDateEnd.save(hospitalDateEnd)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </div>
              </div>
              <v-row>
                <v-col>
                  <v-btn
                      @click="search()"
                  >Поиск
                  </v-btn>
                  <v-btn class="ml-4"
                         @click="clearSearch()"
                  >
                    Очистить
                  </v-btn>
                </v-col>
              </v-row>
            </div>

          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-data-table
        hide-default-footer
        :mobile-breakpoint="0"
        :headers="headers"
        :items="medicalCardList"
        :items-per-page="pageSize"
        @click:row="cardEdit"
        class="elevation-1"
        :footer-props="{
            itemsPerPageText:'Элементов на странице',
        }"
        loading-text="Загрузка..."
        no-data-text="Нет информации"
        :loading="loading"
    >
      <template v-slot:item.id="{ item }">
        <v-chip
            :color="item.resident.color"
        >{{ item.id }}</v-chip>
      </template>

      <template v-slot:item.resident="{ item }">
        <div><b>Фио:</b>
          <span>{{ item.resident.surname }} {{ item.resident.name }} {{ item.resident.middleName }}</span></div>
        <div><b>Согласие:</b> <span>{{ getAgreement(item.resident.agreement) }}</span></div>
        <div><b>Город:</b> <span>{{ item.resident.city }}</span></div>
        <div><b>Адрес:</b> <span>{{ item.resident.address }}</span></div>
        <div><b>Телефон:</b> <span>{{ getPhone(item.resident) }}</span></div>
        <div><b>Дата обращения:</b> <span>{{ item.resident.orderDate }}</span></div>
      </template>

      <template v-slot:item.resident.representatives="{ item }">
        <div
            v-for="representative in item.resident.representatives"
            :key="representative.id"
            class="text-no-wrap"
        >
          <span>{{ representative.surname }} {{ representative.name }} {{ representative.middleName }}</span><span
            class="text-no-wrap">{{ getRepresentPhone(representative.phones) }}</span>
        </div>
      </template>

      <template v-slot:item.activeTask="{ item }">
        <div
            v-for="task in item.activeTask"
            :key="task.id"
            class="text-no-wrap"
        >
          <b>Звонок - </b> <span>{{ task.date }}</span>
        </div>
      </template>

      <template v-slot:item.responsible="{ item }">
        <div
            v-for="user in item.responsible"
            :key="user.id"
        >
          <b>{{ getUserName(user) }}</b>
        </div>

      </template>

<!--      <template v-slot:item.link="{ item }">-->
<!--        <v-btn icon @click="cardEdit(item)">-->
<!--          <v-icon>settings</v-icon>-->
<!--        </v-btn>-->
<!--      </template>-->
    </v-data-table>
    <v-col>
      <v-pagination
          class="my-4"
          v-model="pagination.page"
          :length="pages"
          total-visible="7"
          circle
          color="#36a2eb"
      >

      </v-pagination>
    </v-col>
    <new-medical-card
        :dialog="newMedicalDialog"
        @closeDialog="newMedicalDialog = false"
    >
    </new-medical-card>

  </div>
</template>

<script>
import loader from "../../mixins/loader"
import NewMedicalCard from "../../components/dialogs/medicalCard/NewMedicalCard"

export default {
  name: "MedicalCardList",
  data() {
    return {
      modal: false,
      modalChronicDateStart: false,
      orderDateStartModel: false,
      modalChronicDateEnd: false,
      orderDateEndModal: false,
      hospitalDateStartModal: false,
      hospitalDateEndModal: false,

      males: [{text: "М", value: "MAN"}, {text: "Ж", value: "WOMAN"}],
      agreements: [{text: "Есть", value: 1}, {text: "Нет", value: 0}],
      responsibles: [],
      chronics: [],
      services: [],


      newMedicalDialog: null,

      headers: [
        {text: 'N', align: 'start', sortable: true, value: 'id',},
        {text: 'Резидент', align: 'start', sortable: true, value: 'resident', width: 350},
        {text: 'Представитель', align: 'start', sortable: true, value: 'resident.representatives',},
        {text: 'Активные задачи', align: 'start', sortable: true, value: 'activeTask',},
        {text: 'Ответственные', align: 'start', sortable: true, value: 'responsible',},
        // {text: '', align: 'start', sortable: true, value: 'link',},
      ],
      pageSize: 15,
      totalItems: 0,
      medicalCardList: [],
      pagination: {
        page: 1,
      },
      pages: 1,
      loading: false
    }
  },
  computed: {
    id: {
      get: function () {
        return this.$store.getters["medicalCards/getSearchId"]
      },
      set: function (value) {
        this.$store.dispatch("medicalCards/setSearchId", value)
      }
    },
    surname: {
      get: function () {
        return this.$store.getters["medicalCards/getSearchSurname"]
      },
      set: function (value) {
        this.$store.dispatch("medicalCards/setSearchSurname", value)
      }
    },
    name: {
      get: function () {
        return this.$store.getters["medicalCards/getSearchName"]
      },
      set: function (value) {
        this.$store.dispatch("medicalCards/setSearchName", value)
      }
    },
    middleName: {
      get: function () {
        return this.$store.getters["medicalCards/getSearchMiddleName"]
      },
      set: function (value) {
        this.$store.dispatch("medicalCards/setSearchMiddleName", value)
      }
    },
    birthday: {
      get: function () {
        return this.$store.getters["medicalCards/getSearchBirthday"]
      },
      set: function (value) {
        this.$store.dispatch("medicalCards/setSearchBirthday", value)
      }
    },
    male: {
      get: function () {
        return this.$store.getters["medicalCards/getSearchMale"]
      },
      set: function (value) {
        this.$store.dispatch("medicalCards/setSearchMale", value)
      }
    },
    phone: {
      get: function () {
        return this.$store.getters["medicalCards/getSearchPhone"]
      },
      set: function (value) {
        this.$store.dispatch("medicalCards/setSearchPhone", value)
      }
    },
    comment: {
      get: function () {
        return this.$store.getters["medicalCards/getSearchComment"]
      },
      set: function (value) {
        this.$store.dispatch("medicalCards/setSearchComment", value)
      }
    },
    agreement: {
      get: function () {
        return this.$store.getters["medicalCards/getSearchAgreement"]
      },
      set: function (value) {
        this.$store.dispatch("medicalCards/setSearchAgreement", value)
      }
    },
    responsible: {
      get: function () {
        return this.$store.getters["medicalCards/getSearchResponsible"]
      },
      set: function (value) {
        this.$store.dispatch("medicalCards/setSearchResponsible", value)
      }
    },
    chronic: {
      get: function () {
        return this.$store.getters["medicalCards/getSearchChronic"]
      },
      set: function (value) {
        this.$store.dispatch("medicalCards/setSearchChronic", value)
      }
    },
    service: {
      get: function () {
        return this.$store.getters["medicalCards/getSearchService"]
      },
      set: function (value) {
        this.$store.dispatch("medicalCards/setSearchService", value)
      }
    },
    responsibleService: {
      get: function () {
        return this.$store.getters["medicalCards/getSearchResponsibleService"]
      },
      set: function (value) {
        this.$store.dispatch("medicalCards/setSearchResponsibleService", value)
      }
    },
    serviceDateStart: {
      get: function () {
        return this.$store.getters["medicalCards/getSearchServiceDateStart"]
      },
      set: function (value) {
        this.$store.dispatch("medicalCards/setSearchServiceDateStart", value)
      }
    },
    serviceDateEnd: {
      get: function () {
        return this.$store.getters["medicalCards/getSearchServiceDateEnd"]
      },
      set: function (value) {
        this.$store.dispatch("medicalCards/setSearchServiceDateEnd", value)
      }
    },
    orderDateStart: {
      get: function () {
        return this.$store.getters["medicalCards/getSearchOrderDateStart"]
      },
      set: function (value) {
        this.$store.dispatch("medicalCards/setSearchOrderDateStart", value)
      }
    },
    orderDateEnd: {
      get: function () {
        return this.$store.getters["medicalCards/getSearchOrderDateEnd"]
      },
      set: function (value) {
        this.$store.dispatch("medicalCards/setSearchOrderDateEnd", value)
      }
    },
    hospitalDateStart: {
      get: function () {
        return this.$store.getters["medicalCards/getSearchHospitalDateStart"]
      },
      set: function (value) {
        this.$store.dispatch("medicalCards/setSearchHospitalDateStart", value)
      }
    },
    hospitalDateEnd: {
      get: function () {
        return this.$store.getters["medicalCards/getSearchHospitalDateEnd"]
      },
      set: function (value) {
        this.$store.dispatch("medicalCards/setSearchHospitalDateEnd", value)
      }
    },
    city: {
      get: function () {
        return this.$store.getters["medicalCards/getSearchCity"]
      },
      set: function (value) {
        this.$store.dispatch("medicalCards/setSearchCity", value)
      }
    },
    address: {
      get: function () {
        return this.$store.getters["medicalCards/getSearchAddress"]
      },
      set: function (value) {
        this.$store.dispatch("medicalCards/setSearchAddress", value)
      }
    },
  },
  components: {
    NewMedicalCard,
  },
  watch: {
    'pagination.page'(newPage) {
      localStorage.setItem("paginationPage", newPage);
      this.medicalCardList = []
      this.getMedicalCards()
    },
  },
  mixins: [loader],
  methods: {
    getUserName(user) {
      let userName = user.surname
      if (user.name && user.middleName) {
        userName += " " + user.name[0] + "." + user.middleName[0]
      }
      if (user.position) {
        userName += " (" + user.position + ")"
      }
      return userName
    },
    search(){
      this.pagination.page = 1;
      this.totalItems = 0;
      this.pages = 1;
      this.getMedicalCards()
    },
    clearSearch() {
      this.$store.dispatch("medicalCards/clearSearch")
    },
    getRepresentPhone(phones) {
      return phones.length > 0 ? ", Телефон - " + phones[0].phone : "";
    },
    getPhone(item) {
      return item?.phones?.length > 0 ? item?.phones[0].phone : "";
    },
    getAgreement(agreement) {
      return agreement ? "Есть" : "Нет"
    },
    async cardEdit(item) {
      await this.$store.dispatch("medicalCards/setMedicalCard", item)
      await this.$router.push('medical-card')
    },
    async getMedicalCards() {
      const user = await this.$store.dispatch("users/getAuthUser")
      let page = this.pagination.page;
      let size = this.pageSize;
      this.loading = true;
      this.httpRequest(
          this.$api.users.searchMedicalCards, {
            id: user.id,
            request: this.$store.getters["medicalCards/getSearchRequest"],
            page,
            size
          },
          (response) => {
            this.medicalCardList = response.data.content
            this.totalItems = response.data.totalElements
            this.pages = response.data.totalPages
            this.loading = false
          }
      )
    },
    getFioR(item) {
      return item.surname + " " + item.name + " " + item.middleName
    },
    getResponsible() {
      this.httpRequest(
          this.$api.users.getAll,
          null,
          (response) => {
            this.responsibles = response.data
          }
      )
    },
    getChronic() {
      this.httpRequest(
          this.$api.chronicIllness.getAll,
          null,
          (response) => {
            this.chronics = response.data
          }
      )
    },
    getService() {
      this.httpRequest(
          this.$api.service.getAll,
          null,
          (response) => {
            this.services = response.data
          }
      )
    },

  },
  mounted() {
    this.getMedicalCards();
    this.getResponsible();
    this.getChronic();
    this.getService();
    this.hospitalDateStart = null
    this.hospitalDateEnd = null
    this.serviceDateStart = null
    this.serviceDateEnd = null

    const savedPage = localStorage.getItem("paginationPage");
    if (savedPage) {
      this.pagination.page = parseInt(savedPage, 10);
    }

  },
  created() {
  },

}
</script>

<style lang="scss" scoped>
.row-filter {
  display: flex;
  flex-direction: row;
  justify-content: start;
  flex-wrap: wrap;
}

.filter-wrap {
  display: flex;
  flex-direction: column;
}
</style>
