<template>
  <v-row justify="space-around">
    <v-col cols="auto">
      <v-dialog
          transition="dialog-top-transition"
          max-width="600"
          v-model="dialogs"
      >

          <v-card>
            <v-toolbar
                color="primary"
                dark
            >Редактировать телефон
            </v-toolbar>
            <v-card-text>
              <v-col
                  cols="12"
                  md="4"
              >
                <v-text-field-integer
                    v-model="phone.phone"
                    label="Телефон"
                    required
                    v-bind:properties="{
                        readonly: false,
                        disabled: false,
                        outlined: false,
                        clearable: true,
                        placeholder: '',
                      }"
                    v-bind:options="{
                        inputMask: '#(###)###-##-##',
                        outputMask: '###########',
                        empty: null,
                        applyAfter: false,
                      }"
                ></v-text-field-integer>
              </v-col>
              <v-col>
                <v-text-field
                    label="Комментарий"
                    hide-details="auto"
                    v-model="phone.comment"
                ></v-text-field>
              </v-col>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn
                  text
                  @click="deletedPhone()"
                  color="error"
              >Удалить
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                  text
                  @click="closeDialog()"
              >Выйти
              </v-btn>
              <v-btn
                  text
                  dark
                  color="primary"
                  @click="savePhone()"
              >Сохранить
              </v-btn>
            </v-card-actions>
          </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import loader from "../../../mixins/loader";

export default {
  name: "EditRepresentativePhones",
  props:{
    dialog: null,
    phone: null,
    type: null,
    representId: null,
  },
  data() {
    return {
      // dialogs: false
    }
  },
  computed:{
    dialogs:{
      get: function () {
        return this.dialog;
      },
      // сеттер:
      set: function () {
        this.$emit("closeDialog")
      }
    }
  },
  mixins: [loader],
  methods:{
    closeDialog(){
      this.$emit("refreshCard")
      this.$emit("closeDialog")    },
    savePhone(){
      if (this.type === 'NEW_REPRESENT'){
        this.addPhoneResident()
      }else {
        this.updatePhone()
      }
    },
    addPhoneResident(){
      this.httpRequest(
          this.$api.representative.addPhone,
          {
            id: this.representId,
            phone: this.phone
          },
          () => {
            this.$emit("refreshCard")
            this.$emit("closeDialog")
          }
      )
    },
    updatePhone(){
      this.httpRequest(
          this.$api.phone.update,
          this.phone,
          () => {
            this.$emit("refreshCard")
            this.$emit("closeDialog")
          }
      )
    },
    deletedPhone(){
      this.httpRequest(
          this.$api.phone.deleted,
          this.phone.id,
          () => {
            this.$emit("refreshCard")
            this.$emit("closeDialog")
          }
      )
    },
  }
}
</script>

<style scoped>

</style>
