<template>
  <div class="ml-4">
    <div><b>Представители:</b>
      <v-btn icon @click="newRepresentDialog=true">
        <v-icon>add</v-icon>
      </v-btn>
    </div>
    <v-row class="ma-4">
      <v-autocomplete
          v-model="representative"
          :items="resident.representatives"
          label="Представитель"
          return-object
          :item-text="getRepresentativeTitle"
          item-value="id"
      ></v-autocomplete>
    </v-row>
    <v-row>
      <representative
          :representative="representative"
          @refreshCard="refreshList"
      ></representative>
    </v-row>
    <new-representative
        :dialog="newRepresentDialog"
        :residentId="resident.id"
        @closeDialog="closeNewRepresentDialog"
        @refreshCard="$emit('refreshCardR')"
    ></new-representative>
  </div>
</template>

<script>
import Representative from "./Representative"
import NewRepresentative from "../dialogs/medicalCard/NewRepresentative"

export default {
  name: "RepresentativeList",
  props: {
    resident: null,
  },
  data() {
    return {
      newRepresentDialog: false,
      representative: null,
    }
  },

  components: {
    Representative,
    NewRepresentative,
  },
  methods: {
    getRepresentativeTitle(item){
      return item.surname + " " + item.name + " " + item.middleName + " - "  + item.relations
    },
    closeNewRepresentDialog() {
      this.newRepresentDialog = false;
    },
    refreshList(){
      // console.log(this.resident?.representatives)
      // if (this.resident?.representatives.length > 0){
      //   this.representative = this.resident?.representatives[0]
      // }
      this.$emit('refreshCardR');
    },
  },
  mounted() {
    this.$emit('refreshCard');
  }
}
</script>

<style scoped>

</style>
