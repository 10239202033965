<template>
  <v-row justify="space-around">
    <v-col cols="auto">
      <v-dialog
          transition="dialog-top-transition"
          max-width="600"
          v-model="dialogs"
      >

        <v-card>
          <v-toolbar
              color="primary"
              dark
          >Новая мед карта
          </v-toolbar>
          <v-card-text>
            <div class="d-flex flex-row justify-space-around flex-wrap pt-4">
              <div>
                <h2>Представитель</h2>
                <v-text-field
                    label="Фамилия"
                    hide-details="auto"
                    v-model="residentRepresentative.surname"
                    :disabled="residentRepresentative.relations == undefined || residentRepresentative.relations == ''"
                ></v-text-field>
                <v-text-field
                    label="Имя"
                    v-model="residentRepresentative.name"
                    hide-details="auto"
                    :disabled="residentRepresentative.relations == undefined || residentRepresentative.relations == ''"
                ></v-text-field>
                <v-text-field
                    label="Отчество"
                    v-model="residentRepresentative.middleName"
                    hide-details="auto"
                    :disabled="residentRepresentative.relations == undefined || residentRepresentative.relations == ''"
                ></v-text-field>
                <v-text-field
                    label="Отношение"
                    hide-details="auto"
                    v-model="residentRepresentative.relations"
                >
                  <!--                <template #label>-->
                  <!--                  <span class="red&#45;&#45;text"><strong>* </strong></span>Отношение-->
                  <!--                </template>-->
                </v-text-field>
                <v-radio-group
                    v-model="residentRepresentative.male"
                    row
                    :disabled="residentRepresentative.relations == undefined || residentRepresentative.relations == ''"
                >
                  <v-radio
                      label="М"
                      value="MAN"
                  ></v-radio>
                  <v-radio
                      label="Ж"
                      value="WOMAN"
                  ></v-radio>
                </v-radio-group>
                <v-text-field-integer
                    v-model="residentRepresentativePhone"
                    label="Телефон *"
                    v-bind:properties="{
                        readonly: false,
                        disabled: residentRepresentative.relations == undefined || residentRepresentative.relations == '',
                        outlined: false,
                        clearable: true,
                        placeholder: '',
                      }"
                    v-bind:options="{
                        inputMask: '#(###)###-##-##',
                        outputMask: '###########',
                        empty: null,
                        applyAfter: false,
                      }"
                >
                </v-text-field-integer>
                <v-dialog
                    ref="dialog"
                    v-model="modal"
                    :return-value.sync="resident.orderDate"
                    persistent
                    width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field class="mt-4"
                                  v-model="resident.orderDate"
                                  label="Дата обращения"
                                  prepend-icon="event"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                      v-model="resident.orderDate"
                      scrollable
                      locale="ru-RU"
                      first-day-of-week=1
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        color="primary"
                        @click="modal = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                        text
                        color="primary"
                        @click="$refs.dialog.save(resident.orderDate)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </div>
              <div>
                <h2>Резидент</h2>
                <v-text-field
                    label="Фамилия"
                    hide-details="auto"
                    v-model="resident.surname"
                ></v-text-field>
                <v-text-field
                    label="Имя"
                    v-model="resident.name"
                    hide-details="auto"
                ></v-text-field>
                <v-text-field
                    label="Отчество"
                    v-model="resident.middleName"
                    hide-details="auto"
                ></v-text-field>
                <!--              <v-dialog-->
                <!--                  ref="dialog"-->
                <!--                  v-model="modal"-->
                <!--                  :return-value.sync="resident.birthday"-->
                <!--                  persistent-->
                <!--                  width="290px"-->
                <!--              >-->
                <!--                <template v-slot:activator="{ on, attrs }">-->
                <!--                  <v-text-field class="mt-4"-->
                <!--                                v-model="resident.birthday"-->
                <!--                                label="Дата рождения"-->
                <!--                                prepend-icon="event"-->
                <!--                                readonly-->
                <!--                                v-bind="attrs"-->
                <!--                                v-on="on"-->
                <!--                  ></v-text-field>-->
                <!--                </template>-->
                <!--                <v-date-picker-->
                <!--                    v-model="resident.birthday"-->
                <!--                    scrollable-->
                <!--                >-->
                <!--                  <v-spacer></v-spacer>-->
                <!--                  <v-btn-->
                <!--                      text-->
                <!--                      color="primary"-->
                <!--                      @click="modal = false"-->
                <!--                  >-->
                <!--                    Cancel-->
                <!--                  </v-btn>-->
                <!--                  <v-btn-->
                <!--                      text-->
                <!--                      color="primary"-->
                <!--                      @click="$refs.dialog.save(resident.birthday)"-->
                <!--                  >-->
                <!--                    OK-->
                <!--                  </v-btn>-->
                <!--                </v-date-picker>-->
                <!--              </v-dialog>-->
                <v-checkbox style="margin-top: 25px; margin-bottom: -25px"
                            v-model="resident.agreement"
                            label="Согласие"
                ></v-checkbox>
                <v-radio-group
                    v-model="resident.male"
                    row
                >
                  <v-radio
                      label="М"
                      value="MAN"
                  ></v-radio>
                  <v-radio
                      label="Ж"
                      value="WOMAN"
                  ></v-radio>
                </v-radio-group>
                <v-text-field-integer
                    v-model="residentPhone"
                    label="Телефон"
                    required
                    v-bind:properties="{
                        readonly: false,
                        disabled: false,
                        outlined: false,
                        clearable: true,
                        placeholder: '',
                      }"
                    v-bind:options="{
                        inputMask: '#(###)###-##-##',
                        outputMask: '###########',
                        empty: null,
                        applyAfter: false,
                      }"
                ></v-text-field-integer>
              </div>

            </div>

          </v-card-text>

          <v-card-actions class="justify-end">
            <v-spacer></v-spacer>
            <v-btn
                text
                @click="closeDialog()"
            >Выйти
            </v-btn>
            <v-btn
                text
                dark
                color="primary"
                @click="saveMedicalCard()"
            >Сохранить
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>

</template>

<script>

import loader from "../../../mixins/loader";

export default {
  name: "NewMedicalCard",
  props: {
    dialog: null,
    name: null,
    surname: null,
    middleName: null,
    relations: null,
    phone: null,
    comment: null,
    bidId: null,
  },
  data() {
    return {
      modal: false,
      residentPhone: "",
      residentRepresentativePhone: "",
      resident: {
        surname: "",
        name: "",
        middleName: "",
        birthday: null,
        orderDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        agreement: "",
        male: "MAN",
        region: "",
        city: "",
        address: "",
        phones: [],
      },
      residentRepresentative: {
        surname: "",
        name: "",
        middleName: "",
        birthday: "",
        male: "MAN",
        region: "",
        city: "",
        address: "",
        relations: "",
        phones: [],
      },
      rules: {
        required: value => !!value || 'Required.',
        min: v => v.length >= 8 || 'Min 8 characters',
      },
    }
  },
  watch: {
    name() {
      if (this.name != null) {
        this.residentRepresentative.name = this.name
      }
    },
    surname() {
      if (this.surname != null) {
        this.residentRepresentative.surname = this.surname
      }
    },
    middleName() {
      if (this.middleName != null) {
        this.residentRepresentative.middleName = this.middleName
      }
    },
    relations() {
      if (this.relations != null) {
        this.residentRepresentative.relations = this.relations
      }
    },
    phone() {
      if (this.phone != null) {
        this.residentRepresentativePhone = this.phone
      }
    },
    residentPhone() {
      if (this.residentPhone != null && this.residentPhone.trim() != "") {
        this.resident.phones = [];
        this.resident.phones.push({
          phone: this.residentPhone,
          type: "RESIDENT"
        })
      }
    },
    residentRepresentativePhone() {
      if (this.residentRepresentativePhone != null && this.residentRepresentativePhone.trim() != "") {
        this.residentRepresentative.phones = [];
        this.residentRepresentative.phones.push({
          phone: this.residentRepresentativePhone,
          type: "REPRESENT"
        })
      }

    },
  },
  computed: {
    dialogs: {
      get: function () {
        return this.dialog;
      },
      // сеттер:
      set: function () {
        this.$emit("closeDialog")
      }
    }
  },

  mixins: [loader],
  methods: {
    closeDialog() {
      this.$emit("closeDialog")
    },

    saveMedicalCard() {
      this.httpRequest(
          this.$api.medicalCard.newMedicalCard, {
            residentDto: this.resident,
            residentRepresentativeDto: this.residentRepresentative,
            bidId: this.bidId,
            comment: this.comment,
          },
          async (response) => {
            await this.$store.dispatch("medicalCards/setMedicalCard", response.data)
            await this.$router.push('/medical-card/medical-card')
          }
      )
    },
  },
}
</script>

<style scoped>

</style>
