
export default {
    ///api/v1/swagger-ui/ test

    API: {
        // URL_DEFAULT: 'http://localhost:8081/api/v1',
        URL_DEFAULT: '/api/v1',
    },

}

