<template>
  <div>
    <div><b>Услуги:</b>
      <v-btn icon @click="dialogNewOrder=true">
        <v-icon>add</v-icon>
      </v-btn>
    </div>
    <div>
      <v-data-table
          :mobile-breakpoint="0"
          :headers="headers"
          :items="orders"
          :items-per-page="5"
          class="elevation-1"
          :footer-props="{
            itemsPerPageText:'Элементов на странице',
        }"
      >
        <template v-slot:item.date="{ item }">
          {{item.date | date}}
        </template>
        <template v-slot:item.responsible="{ item }">
          <div>Создал: {{ getUserName(item.creator) }}</div>
          <div v-if="item.responsible">Исполнитель: {{ getUserName(item.responsible) }}</div>
        </template>
        <template v-slot:item.link="{ item }">
          <v-btn v-if="isAdmin || isSeniorManager"  icon @click="orderEdit(item)" >
            <v-icon >settings</v-icon>
          </v-btn>

        </template>
      </v-data-table>
    </div>
    <new-order
        :dialog="dialogNewOrder"
        :medicalCardId="medicalCardId"
        :services="services"
        @refreshOrders="getOrders"
        @closeDialog="closeDialogNewOrder"
        @refreshService="getServices"
    ></new-order>
    <edit-order
        :dialog="dialogEditOrder"
        :medicalCardId="medicalCardId"
        :services="services"
        :order-id="orderId"
        @refreshOrders="getOrders"
        @closeDialog="closeDialogEditOrder"
        @refreshService="getServices"
    ></edit-order>
  </div>
</template>

<script>
// import Order from './Order'
import NewOrder from "../dialogs/order/NewOrder";
import loader from "../../mixins/loader";
import EditOrder from "../dialogs/order/EditOrder.vue";

export default {
  name: "OrderList",
  props: {
    medicalCardId: null,
  },
  data() {
    return {
      dialogNewOrder: false,
      dialogEditOrder: false,
      isAdmin: false,
      isSeniorManager: false,
      orderId: null,
      orders: [],
      headers: [
        {text: 'N', align: 'start', sortable: true, value: 'id',},
        {text: 'Услуга', align: 'start', sortable: true, value: 'service.title',},
        {text: 'Дата', align: 'start', sortable: true, value: 'date',},
        {text: 'Ответственные', align: 'start', sortable: true, value: 'responsible',},
        {text: 'Цена', align: 'start', sortable: true, value: 'price',},
        {text: '', align: 'start', sortable: true, value: 'link',},
      ],
      services: [],
    }
  },
  components: {
    EditOrder,
    NewOrder,
    // EditResponsible,
  },
  mixins: [loader],
  filters:{
    date(date){
        return date.slice(0, 10)
    }
  },
  methods: {
    orderEdit(item){
      this.orderId = item.id
      this.dialogEditOrder = true
    },
    getUserName(user){
      let userName = user.surname
      if (user.name && user.middleName){
        userName += " " + user.name[0] + "." +  user.middleName[0]
      }
      if (user.position){
        userName += " (" + user.position + ")"
      }
      return  userName
    },
    closeDialogNewOrder() {
      this.dialogNewOrder = false;
    },
    closeDialogEditOrder() {
      this.dialogEditOrder = false;
    },
    getOrders() {
      this.httpRequest(
          this.$api.medicalCard.getOrders,
          {
            id: this.medicalCardId
          },
          (response) => {
            this.orders = response.data
          }
      )
    },

    getServices(){
      this.httpRequest(
          this.$api.service.getAll,
          null,
          (response) => {
            this.services = response.data
          }
      )
    },

  },
  async mounted() {
    this.getOrders()
    this.getServices()
    this.isAdmin = await this.$store.dispatch("users/hasRoleAuthUser", "ROLE_ADMIN")
    this.isSeniorManager = await this.$store.dispatch("users/hasRoleAuthUser", "ROLE_SENIOR_MANGER")
  }
}
</script>

<style scoped>

</style>
