<template>
  <div>
    <div class="d-flex justify-lg-center ml-4"><b class="pt-1">Задачи:</b>
      <v-btn icon @click="newTaskDialog=true">
        <v-icon>add</v-icon>
      </v-btn>
    </div>
    <v-data-table
        :mobile-breakpoint="0"
        :headers="headers"
        :items="tasks"
        :items-per-page="5"
        class="elevation-1"
        :footer-props="{
            itemsPerPageText:'Элементов на странице',
        }"
    >

      <template v-slot:item.id="{ item }">
        <div><b>{{ item.id}}</b> </div>
      </template>

      <template v-slot:item.creator="{ item }">
        <div class="text-no-wrap"><b>Создал:</b> <span>{{ getUserName(item.creator) }}</span></div>
        <div class="text-no-wrap"><b>Исполнитель:</b> <span>{{ getUserName(item.performer) }}</span></div>
        <div class="text-no-wrap"><b>Статус:</b> <span>{{getStatus(item.status)}}</span></div>
        <div style="max-width: 350px;" ><b>Комментарий:</b> <span>{{item.comment}}</span></div>
      </template>


      <template v-slot:item.importance="{ item }">
        <div>{{ getImportance(item.importance) }}</div>
      </template>

      <template v-slot:item.date="{ item }">
        <div class="text-no-wrap"><b>Дата:</b> {{ item.date }}</div>
        <div class="text-no-wrap"><b>Время:</b> {{ item.time }}</div>
        <div class="text-no-wrap"><b>Дата исполнения:</b> {{  item.dateCompletion }}</div>
        <div class="text-no-wrap"><b>Время исполнения:</b> {{  item.timeCompletion }}</div>
      </template>

      <template v-slot:item.link="{ item }">
        <v-btn v-if="showEditTaskIcon && item.status === 'ACTIVE'"  icon @click="taskEdit(item)" >
          <v-icon >settings</v-icon>
        </v-btn>
        <v-btn  v-if="item.status === 'ACTIVE'"  icon @click="taskDone(item)">
          <v-icon color="green">done</v-icon>
        </v-btn>
      </template>

    </v-data-table>
    <new-task
        :dialog="newTaskDialog"
        :medicalCardId="medicalCardId"
        :users="users"
        :taskTypes="taskTypes"
        @closeDialog="newTaskDialog = false"
        @refreshCard="getTasks"
    ></new-task>
    <edit-task
        :dialog="taskEditDialog"
        :task="selectTaskEdit"
        :users="users"
        :taskTypes="taskTypes"
        @closeDialog="taskEditDialog = false"
        @refreshCard="getTasks"
    ></edit-task>
    <task-done
        :dialog="taskDoneDialog"
        :task="selectTaskDone"
        :medicalCardId="medicalCardId"
        @closeDialog="closeDoneTaskDialog"
        @refreshTask="refreshTaskAndComment"
    ></task-done>
  </div>
</template>

<script>
import loader from "../../mixins/loader";
import NewTask from "../dialogs/medicalCard/NewTask"
import EditTask from "../dialogs/medicalCard/EditTask"
import TaskDone from "../dialogs/medicalCard/TaskDone"


export default {
  name: "TaskList",
  props: {
    medicalCardId: null,
  },
  data() {
    return {
      headers: [
        {text: '№', align: 'start', sortable: true, value: 'id'},
        {text: 'Ответственные', align: 'start', sortable: true, value: 'creator',},
        {text: 'Важность', align: 'start', sortable: true, value: 'importance',},
        {text: 'Даты', align: 'start', sortable: true, value: 'date',},
        {text: 'Тип', align: 'start', sortable: true, value: 'type.title',},
        {text: '', align: 'start', sortable: true, value: 'link',},
      ],
      tasks: [],
      newTaskDialog: false,
      taskEditDialog: false,
      taskDoneDialog: false,
      users:[],
      taskTypes:[],
      selectTaskEdit: null,
      selectTaskDone: null,
      showEditTaskIcon: false,
    }
  },
  components: {
    EditTask,
    NewTask,
    TaskDone,
  },
  filters: {
    date(date) {
      return date.slice(0, 10)
    }
  },
  mixins: [loader],
  methods: {
    getUserName(user){
      let userName = user.surname
      if (user.name && user.middleName){
        userName += " " + user.name[0] + "." +  user.middleName[0]
      }
      if (user.position){
        userName += " (" + user.position + ")"
      }
      return  userName
    },
    refreshTaskAndComment(){
      this.getTasks()
      this.$emit("refreshComment")
    },
    closeDoneTaskDialog(){
      this.taskDoneDialog = false;
    },
    taskDone(task){
      this.selectTaskDone = task
      this.taskDoneDialog = true;
    },
    getStatus(status){
      if (status === "ACTIVE"){
        return "В работе"
      }
      if (status === "NOT_ACTIVE"){
        return "Исполнено"
      }
    },
    async isAdmin() {
      this.showEditTaskIcon = (await this.$store.dispatch('users/hasRoleAuthUser', 'ROLE_ADMIN'));
    },
    taskEdit(item){
      this.selectTaskEdit = item
      this.taskEditDialog = true
    },
    getImportance(item) {
      if (item === "HIGH_IMPORTANCE") {
        return "Высокая"
      }
      if (item === "MEDIUM_IMPORTANCE") {
        return "Средняя"
      }
      if (item === "LOW_IMPORTANCE") {
        return "Низкая"
      }
    },
    getTasks() {
      this.httpRequest(
          this.$api.medicalCard.getTasks,
          this.medicalCardId,
          (response) => {
            this.tasks = response.data
          }
      )
    },
    getUsers() {
      this.httpRequest(
          this.$api.users.getAll,
          null,
          (response) => {
            this.users = response.data
          }
      )
    },
    getTaskTypes() {
      this.httpRequest(
          this.$api.taskType.getAll,
          null,
          (response) => {
            this.taskTypes = response.data
          }
      )
    },
  },
  mounted() {
    this.getTasks()
    this.getUsers()
    this.getTaskTypes()
    this.isAdmin()
  },
}
</script>

<style scoped>

</style>
