export default function (instance){
    return {
        getAll(){
            return instance.get('orders')
        },
        getOne(id){
            return instance.get(`orders/${id}`)
        },
        save(order){
            return instance.post(`orders`, order)
        },
        // update(phone){
        //     return instance.put(`orders/${phone.id}`, phone)
        // },
        deleted(id){
            return instance.delete(`orders/${id}`)
        },
    }
}
