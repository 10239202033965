
export default {
    namespaced: true,

    state: {
        messagesTop:[

        ],
        messageTop: null
    },
    mutations: {
        SET_MESSAGE_TOP(state, message) {
            state.messagesTop.push(message);
        },
        DELETE_ITEM_MESSAGE_TOP(state, message) {
            state.messagesTop = state.messagesTop.filter(function(item) { return item !== message })
        },

    },
    getters: {
        getMessageTop(state) {
            return state.messagesTop
        },
    },
    actions: {
        async setMessageTop({commit}, message){
            message.id = Math.random() + message.type
            commit("SET_MESSAGE_TOP", message)
        },
        async deletedItemMessageTop({commit}, message){
            commit("DELETE_ITEM_MESSAGE_TOP", message)
        },
    },
}
