<template>
  <div>
    <div><b>Хронические заболевания:</b>
    </div>
    <chronic-illness v-for="chronicIllness in chronicIllnesses"
                 :key="chronicIllness.id"
                 :chronicIllness="chronicIllness"
                 :residentId="residentId"
                 @refreshCard="refresh()"
    ></chronic-illness>
  </div>
</template>

<script>
import ChronicIllness from './ChronicIllness'
import loader from "../../mixins/loader";

export default {
  name: "ChronicIllnessList",
  props: {
    residentId: null,
    chronicIllnesses: [],
  },
  data() {
    return {
      dialog: false,
    }
  },
  components: {
    ChronicIllness,
  },
  mixins: [loader],
  methods: {
    // refreshCard() {
    //   this.$emit('refreshCard')
    // },
    closeDialog() {
      this.dialog = false;
    },

    refresh(){
      this.httpRequest(
          this.$api.resident.getChronicIllness,
          {
            id: this.residentId
          },
          (response) => {
            this.chronicIllnesses = response.data
          }
      )
    },

  },
  mounted() {
  }
}
</script>

<style scoped>

</style>
