<template>
  <v-row justify="space-around">
    <v-col cols="auto">
      <v-dialog
          transition="dialog-top-transition"
          max-width="600"
          v-model="dialogs"
      >

        <v-card>
          <v-toolbar
              color="primary"
              dark
          >Редактировать
          </v-toolbar>
          <v-card-text>
            <v-textarea class="mt-4"
                        v-model="medicalCard.info"
                        name="comment"
                        label="Введите комментарий"
            ></v-textarea>
          </v-card-text>
          <v-card-actions class="justify-end">

            <v-spacer></v-spacer>
            <v-btn
                text
                @click="closeDialog()"
            >Выйти
            </v-btn>
            <v-btn
                v-if="isSave()"
                text
                dark
                color="primary"
                @click="saveComment()"
            >Сохранить
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import loader from "../../../mixins/loader";

export default {
  name: "MedicalCardComment",
  props: {
    dialog: null,
    medicalCard: null,
  },
  data() {
    return {
    }
  },
  computed: {
    dialogs: {
      get: function () {
        return this.dialog;
      },
      // сеттер:
      set: function () {
        this.$emit("closeDialog")
      }
    }
  },

  mixins: [loader],
  methods: {
    closeDialog() {
      this.$emit("refreshCard")
      this.$emit("closeDialog")
    },
    isSave() {
      if (this.medicalCard.id != null && this.medicalCard.info) {
        return true
      }
      return false
    },
    saveComment() {
      this.httpRequest(
          this.$api.medicalCard.updateInfo,{
            id: this.medicalCard.id,
            medicalCard: this.medicalCard
          },
          () => {
            this.$emit("closeDialog")
            this.$emit("refreshCard")
          }
      )
    },
  },
}
</script>

<style scoped>

</style>
