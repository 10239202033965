<template>
  <div>
    <v-expansion-panels>
      <v-expansion-panel>
        <v-expansion-panel-header>
          Фильтры
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <div class="filter-wrap">
            <div v-if="isAdmin || isSeniorManager">
              <div>
                <v-autocomplete
                    v-model="user"
                    :items="users"
                    label="Исполнитель"
                    return-object
                    :item-text="getName"
                    item-value="id"
                ></v-autocomplete>
              </div>
            </div>
            <div>
              <v-dialog
                  ref="dialogStart"
                  v-model="modalStart"
                  :return-value.sync="dateStart"
                  persistent
                  width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field class="mt-4"
                                v-model="dateStart"
                                label="Дата с"
                                prepend-icon="event"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="dateStart"
                    scrollable
                    locale="ru-RU"
                    first-day-of-week=1
                >
                  <v-spacer></v-spacer>
                  <v-btn
                      text
                      color="primary"
                      @click="modalStart = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                      text
                      color="primary"
                      @click="$refs.dialogStart.save(dateStart)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-dialog>

            </div>
            <div>
              <v-dialog
                  ref="dialogEnd"
                  v-model="modalEnd"
                  :return-value.sync="dateEnd"
                  persistent
                  width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field class="mt-4"
                                v-model="dateEnd"
                                label="Дата по"
                                prepend-icon="event"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="dateEnd"
                    scrollable
                    locale="ru-RU"
                    first-day-of-week=1
                >
                  <v-spacer></v-spacer>
                  <v-btn
                      text
                      color="primary"
                      @click="modalEnd = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                      text
                      color="primary"
                      @click="$refs.dialogEnd.save(dateEnd)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-dialog>

            </div>
            <div>
              <div>
                <v-btn
                    color="primary"
                    @click="getWorkLogs()"
                >
                  Поиск
                </v-btn>
              </div>
            </div>

          </div>


        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-data-table
        :headers="headers"
        :items="workLogs"
        :items-per-page="15"
        class="elevation-1"
        :footer-props="{
            itemsPerPageText:'Элементов на странице',
        }"
    >
      <template v-slot:item.id="{ item }">
        <div>№: {{item.id}}</div>
        <div>Карта №: {{item.medicalCardId}}</div>
      </template>
      <template v-slot:item.user="{ item }">
        <div>{{getUserName(item.user)}}</div>
      </template>
      <template v-slot:item.date="{ item }">
        <div>Дата: {{item.date}}</div>
        <div>Время: {{item.time}}</div>
      </template>
      <template v-slot:item.link="{ item }">
        <v-btn icon @click="cardEdit(item.medicalCardId)">
          <v-icon >open_in_new</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import loader from "../../mixins/loader";

export default {
  name: "WorkLogList",
  data() {
    return {
      isAdmin: false,
      isSeniorManager: false,
      modalStart: false,
      modalEnd: false,
      users: [],
      user: null,
      dateStart: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      dateEnd: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),

      headers: [
        {text: 'N', align: 'start', sortable: true, value: 'id',},
        {text: 'Пользователь', align: 'start', sortable: true, value: 'user',},
        {text: 'Дата', align: 'start', sortable: true, value: 'date',},
        {text: 'Действие', align: 'start', sortable: true, value: 'log',},
        {text: '', align: 'start', sortable: true, value: 'link',},
      ],
      workLogs: [],
    }
  },
  mixins: [loader],
  methods: {
    getStatus(status){
      if (status === "ACTIVE"){
        return "В работе"
      }
      if (status === "NOT_ACTIVE"){
        return "Исполнено"
      }
    },
    cardEdit(id){
      this.httpRequest(
          this.$api.medicalCard.getOne,
          id,
          async (response) => {
            await this.$store.dispatch("medicalCards/setMedicalCard", response.data)
            await this.$router.push('/medical-card/medical-card')
          }
      )
    },
    getUserName(item){
      let userName = item.surname
      if (item.name && item.middleName){
        userName += " " + item.name[0] + "." +  item.middleName[0]
      }
      if (item.position){
        userName += " (" + item.position + ")"
      }
      return  userName
    },
    getWorkLogs() {
      this.httpRequest(
          this.$api.users.getWorkLog,
          {
            id: this.user.id,
            filterRequest:{
              dateStart: this.dateStart,
              dateEnd: this.dateEnd
            }
          },
          async (response) => {
            this.workLogs = response.data
          }
      )
    },
    getUsers() {
      this.httpRequest(
          this.$api.users.getAll,
          null,
          async (response) => {
            this.users = response.data
            const user = await this.$store.dispatch("users/getAuthUser")
            for (let i = 0; i < this.users.length; i++) {
              if (this.users[i].id === user.id) {
                this.user = this.users[i]
                break;
              }
            }

            if (this.users){
              this.getWorkLogs()
            }

          }
      )
    },
    getName(item) {
      return item.surname + " " + item.name + " " + item.middleName
    },
  },
  async mounted() {
    this.getUsers()
    this.isAdmin = await this.$store.dispatch("users/hasRoleAuthUser", "ROLE_ADMIN")
    this.isSeniorManager = await this.$store.dispatch("users/hasRoleAuthUser", "ROLE_SENIOR_MANGER")
  }

}
</script>

<style scoped>
.filter-wrap{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
}
</style>
