import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home'
import Login from '../views/Login'
import UsesSearch from '../views/users/UsesSearch'
import UserCard from '../views/users/UserCard'
import MedicalCardList from '../views/medicalCard/MedicalCardList'
import MedicalCard from '../views/medicalCard/MedicalCard'
import TasksList from '../views/tasks/TasksList'
import WorkLogList from '../views/logs/WorkLogList'
import EventLogList from '../views/logs/EventLogList'
import BidList from '../views/bid/BidList'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'HospitalMedicalCardList',
        component: Home
    },
    {
        path: '/login',
        name: 'Login',
        component: Login
    },
    {
        path: '/users/users-search',
        name: 'UsesSearch',
        component: UsesSearch
    },
    {
        path: '/users/user-card',
        name: 'UserCard',
        component: UserCard
    },
    {
        path: '/medical-card/medical-card-list',
        name: 'MedicalCardList',
        component: MedicalCardList
    },
    {
        path: '/medical-card/medical-card',
        name: 'MedicalCard',
        component: MedicalCard
    },
    {
        path: '/tasks/task-list',
        name: 'TasksList',
        component: TasksList
    },
    {
        path: '/log/work',
        name: 'WorkLogList',
        component: WorkLogList
    },
    {
        path: '/log/event',
        name: 'EventLogList',
        component: EventLogList
    },
    {
        path: '/bid/bid-list',
        name: 'BidList',
        component: BidList
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
