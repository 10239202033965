<template>
  <div>
    <v-data-table
        :headers="headers"
        :items="bids"
        :items-per-page="15"
        class="elevation-1"
        :footer-props="{
            itemsPerPageText:'Элементов на странице',
        }"
    >
      <template v-slot:item.name="{ item }">
       {{getFio(item)}}
      </template>
      <template v-slot:item.crated="{ item }">
        {{item.crated | date}}
      </template>
      <template v-slot:item.link="{ item }">
        <v-btn class="mr-10" icon @click="newMedCard(item)">
          <v-icon>add</v-icon>
        </v-btn>
        <v-btn icon @click="deleteBid(item)">
          <v-icon>delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <new-medical-card
        :dialog="newMedicalDialog"
        :name="newItem.name"
        :surname="newItem.surname"
        :middleName="newItem.middleName"
        :relations="newItem.relations"
        :phone="newItem.phone"
        :comment="newItem.comment"
        :bidId="newItem.id"
        @closeDialog="newMedicalDialog = false"
    >
    </new-medical-card>
  </div>
</template>

<script>
import loader from "../../mixins/loader"
import NewMedicalCard from "../../components/dialogs/medicalCard/NewMedicalCard"

export default {
  name: "BidList",
  components: {
    NewMedicalCard,
  },
  data(){
    return{
      headers: [
        {text: 'N', align: 'start', sortable: true, value: 'id',},
        {text: 'фио', align: 'start', sortable: true, value: 'name',},
        {text: 'Отношение', align: 'start', sortable: true, value: 'relations',},
        {text: 'Телефон', align: 'start', sortable: true, value: 'phone',},
        {text: 'Комментарий', align: 'start', sortable: true, value: 'comment',},
        {text: 'Дата', align: 'start', sortable: true, value: 'crated',},
        {text: '', align: 'start', sortable: true, value: 'link',},
      ],
      bids:[],
      newMedicalDialog: false,
      newItem: {
        name: null,
        surname: null,
        middleName: null,
        relations: null,
        phone: null,
        comment: null,
      },
    }
  },
  filters: {
    date(date) {
      return date.slice(0, 10)
    }
  },
  mixins: [loader],
  methods:{
    newMedCard(item){
      this.newItem = {...item}
      this.newMedicalDialog = true;
      // return item;
    },
    getFio(item) {
      const surname = item.surname != null? item.surname : "";
      const name = item.name != null? item.name : "";
      const middleName = item.middleName != null? item.middleName : "";
      return surname + " " + name + " " + middleName
    },
    getBids(){
      this.httpRequest(
          this.$api.bid.getAllByStatus,
          "ACTIVE",
          (response) => {
            this.bids = response.data
          }
      )
    },
    deleteBid(item){
      if (confirm("Удалить заявку?")){
        this.httpRequest(
            this.$api.bid.deleted,
            item.id,
            () => {
              this.getBids()
            }
        )
      }
    }
  },
  mounted() {
    this.getBids()
  }
}
</script>

<style scoped>

</style>
