export default function (instance){
    return {
        getAll(){
            return instance.get('tasks')
        },
        getOne(id){
            return instance.get(`tasks/${id}`)
        },
        getByUsersAndDates(requestTasks){
            return instance.post('tasks/find-by-users-and-dates',requestTasks)
        },
        update(request){
            return instance.put(`tasks/${request.id}`, request.task)
        },

    }
}
