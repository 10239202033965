import Api from '@/api'

export default {
    namespaced: true,
    state: {
        authUser: null,
        editUser: {
            id: null,
            username: '',
            email: '',
            name: '',
            middleName: '',
            surname: '',
            password: '',
            phone: '',
            status: 'ACTIVE',
            role: [],
        },
    },
    mutations: {
        SET_AUTH_USER(state, user) {
            state.authUser = user;
        },

        DELETE_AUTH_USER(state) {
            state.authUser = null;
        },

        SET_EDIT_USER(state, user) {
            state.editUser = user;
        },

        DELETE_EDIT_USER(state) {
            state.editUser = {
                id: null,
                username: '',
                email: '',
                name: '',
                middleName: '',
                surname: '',
                password: '',
                phone: '',
                status: 'ACTIVE',
                role: [],
            };
        },
    },
    getters: {
        authUser(state) {
            return state.authUser
        },
        editUser(state) {
            return state.editUser
        },
    },
    actions: {
        setAuthUser({commit}, user) {
            commit('SET_Auth_USER', user)
        },
        //
        async getAuthUser({commit, state, getters}) {
            if (!state.user){
                await Api.users.getAuthUser()
                    .then(response => {
                        const user = response.data
                        commit('SET_AUTH_USER', user)
                    }).catch(() => {
                    commit('DELETE_AUTH_USER')
                })
            }
            return getters.authUser
        },

        async hasRoleAuthUser({getters}, role) {
            return  getters.authUser?.role.find((i) => i.name === role) !== undefined;
        },

        getUserEdit({getters}) {
            return getters.editUser
        },

        deleteAuthUser({commit}) {
            commit('DELETE_AUTH_USER')
        },
    },
}
