<template>
  <v-row justify="space-around">
    <v-col cols="auto">
      <v-dialog
          transition="dialog-top-transition"
          max-width="600"
          v-model="dialogs"

      >
        <v-card>
          <v-toolbar
              color="primary"
              dark
          >Выполнить задачу
          </v-toolbar>
          <v-card-text>
            <v-textarea class="mt-4"
                        v-model="task.commentDone"
                        name="comment"
                        label="Введите комментарий"
            ></v-textarea>
          </v-card-text>
          <v-card-actions class="justify-end">

            <v-spacer></v-spacer>
            <v-btn
                text
                @click="closeDialog()"
            >Выйти
            </v-btn>
            <v-btn
                v-if="isSave()"
                text
                dark
                color="primary"
                @click="taskDone()"
            >Выполнить
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import loader from "../../../mixins/loader";

export default {
  name: "TaskDone",
  props: {
    dialog: null,
    medicalCardId: null,
    task: null,
  },
  data() {
    return {
    }
  },
  computed: {
    dialogs: {
      get: function () {
        return this.dialog;
      },
      // сеттер:
      set: function () {
        this.$emit("closeDialog")
      }
    }
  },

  mixins: [loader],
  methods: {
    closeDialog() {
      this.$emit("refreshTask")
      this.$emit("closeDialog")
    },
    isSave() {
      if (this.medicalCardId != null && this.task.commentDone  && this.task.id) {
        return true
      }
      return false
    },
    taskDone() {
      this.httpRequest(
          this.$api.medicalCard.taskDone,{
            id: this.medicalCardId,
            taskId: this.task.id,
            comment: this.task
          },
          () => {
            this.$emit("closeDialog")
            this.$emit("refreshTask")
          }
      )
    },
  },
  mounted() {
    // console.log(this.task)
  }
}
</script>

<style scoped>

</style>
