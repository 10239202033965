export default function (instance){
    return {
        getAll(){
            return instance.get('medical-card')
        },
        getOne(id){
            return instance.get(`medical-card/${id}`)
        },
        getTasks(id){
            return instance.get(`medical-card/${id}/tasks`)
        },
        deleteResponsible(payload){
            return instance.delete(`medical-card/${payload.id}/responsible/${payload.responsible.id}`)
        },
        addResponsible(payload){
            return instance.put(`medical-card/${payload.id}/responsible`, payload.responsible)
        },
        addTask(payload){
            return instance.put(`medical-card/${payload.id}/tasks`, payload.task)
        },
        getOrders(payload){
            return instance.get(`medical-card/${payload.id}/orders`)
        },
        getResponsible(id){
            return instance.get(`medical-card/${id}/responsible`)
        },
        addOrders(payload){
            return instance.put(`medical-card/${payload.id}/orders`, payload.order)
        },
        getComments(id){
            return instance.get(`medical-card/${id}/comments`)
        },
        addComment(payload){
            return instance.post(`medical-card/${payload.id}/comments`, payload.comment)
        },
        taskDone(payload){
            return instance.put(`medical-card/${payload.id}/tasks/${payload.taskId}/done`, payload.comment)
        },
        updateInfo(payload){
            return instance.put(`medical-card/${payload.id}/info`, payload.medicalCard)
        },
        newMedicalCard(resident){
            return instance.post(`medical-card`, resident)
        },
        delete(id){
            return instance.delete(`medical-card/${id}`)
        },
    }
}
