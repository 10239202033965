<template>
 <div class="d-flex flex-column">
    <div class="d-flex flex-row justify-start flex-wrap">
      <div class="mt-5 ml-10 mr-5 mb-5" >
        <v-text-field
            label="Фамилия"
            hide-details="auto"
            v-model="resident.surname"
        ></v-text-field>
        <v-text-field
            label="Имя"
            v-model="resident.name"
            hide-details="auto"
        ></v-text-field>
        <v-text-field
            label="Отчество"
            v-model="resident.middleName"
            hide-details="auto"
        ></v-text-field>
        <v-dialog
            ref="dialog"
            v-model="modal"
            :return-value.sync="resident.birthday"
            persistent
            width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field class="mt-4"
                v-model="resident.birthday"
                label="Дата рождения"
                prepend-icon="event"
                readonly
                v-bind="attrs"
                v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
              v-model="resident.birthday"
              scrollable
              locale="ru-RU"
              first-day-of-week=1
          >
            <v-spacer></v-spacer>
            <v-btn
                text
                color="primary"
                @click="modal = false"
            >
              Cancel
            </v-btn>
            <v-btn
                text
                color="primary"
                @click="$refs.dialog.save(resident.birthday)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>
        <v-checkbox
            v-model="resident.agreement"
            label="Согласие"
        ></v-checkbox>

        <v-radio-group
            v-model="resident.male"
            row
        >
          <v-radio
              label="М"
              value="MAN"
          ></v-radio>
          <v-radio
              label="Ж"
              value="WOMAN"
          ></v-radio>
        </v-radio-group>
        <v-dialog
            ref="dialogOrderDate"
            v-model="modalOrderDate"
            :return-value.sync="resident.orderDate"
            persistent
            width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field class="mt-4"
                          v-model="resident.orderDate"
                          label="Дата обращения"
                          prepend-icon="event"
                          readonly
                          v-bind="attrs"
                          v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
              v-model="resident.orderDate"
              scrollable
              locale="ru-RU"
              first-day-of-week=1
          >
            <v-spacer></v-spacer>
            <v-btn
                text
                color="primary"
                @click="modalOrderDate = false"
            >
              Cancel
            </v-btn>
            <v-btn
                text
                color="primary"
                @click="$refs.dialogOrderDate.save(resident.orderDate)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>
        <v-dialog
            ref="dialogHospitalDate"
            v-model="modalHospitalDate"
            :return-value.sync="resident.hospitalDate"
            persistent
            width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field class="mt-4"
                          v-model="resident.hospitalDate"
                          label="Стационар"
                          prepend-icon="event"
                          readonly
                          v-bind="attrs"
                          v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
              v-model="resident.hospitalDate"
              scrollable
              locale="ru-RU"
              first-day-of-week=1
          >
            <v-spacer></v-spacer>
            <v-btn
                text
                color="primary"
                @click="modalHospitalDate = false"
            >
              Cancel
            </v-btn>
            <v-btn
                text
                color="primary"
                @click="$refs.dialogHospitalDate.save(resident.hospitalDate)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>
        <v-autocomplete
            class="mt-4"
            v-model="resident.color"
            :items="colors"
            label="Цвет"
        ></v-autocomplete>
      </div>
      <div class="mt-5 ml-10 mr-5 mb-5">
        <v-text-field
          label="Область"
          v-model="resident.region"
          hide-details="auto"
      ></v-text-field>
        <v-text-field
            label="Город"
            v-model="resident.city"
            hide-details="auto"
        ></v-text-field>
        <v-textarea class="mt-3"
            name="input-7-1"
            label="Адрес"
            rows="3"
            v-model="resident.address"
            hint="Введите адрес"
        ></v-textarea>
        <v-card class="pa-2">
          <responsible-list
              :responsibles="responsibles"
              :residentId="resident.id"
              :medicalCardId="medicalCardId"
              @refreshCard="$emit('refreshResponsible')"
          ></responsible-list>
        </v-card>

      </div>
      <div class="mt-10 ml-10 mr-5 mb-5 d-flex flex-column justify-space-between">
        <v-card class="pa-2">
          <phones-list
              :phones="resident.phones"
              :residentId="resident.id"
              @refreshCard="getPhone"
          ></phones-list>
        </v-card>
        <v-card class="pa-2 mt-4">
          <chronic-illness-list
              class="mt-4"
              :residentId="resident.id"
              :chronicIllnesses="residentChronicIllness"
              @refreshCard="$emit('refreshCard')"
          />
          <v-combobox
              v-model="chronicIllness"
              :items="chronicIllnessses"
              label="Добавить"
              item-text="title"
              item-value="id"
              hide-selected
              persistent-hint

          ></v-combobox>
        </v-card>
        <div class="d-flex flex-row-reverse mt-4">

          <v-btn
              color="primary"
              @click="saveResident()"
          >
            Сохранить
          </v-btn>
          <v-btn v-if="isAdmin" style="margin-right: 20px"
                 color="error"
                 @click="deleteCard()"
          >
            Удалить
          </v-btn>
        </div>

      </div>
    </div>
   <v-divider></v-divider>
    <div class="">
      <div class="mt-5 ml-4 mr-4 mb-5">
        <order-list
            :medicalCardId="medicalCardId"
        ></order-list>
      </div>
    </div>
 </div>
</template>

<script>
import loader from "../../mixins/loader";
import PhonesList from "./PhonesList";
import ResponsibleList from "./ResponsibleList";
import ChronicIllnessList from "./ChronicIllnessList";
import OrderList from "../order/OrderList";

export default {
  name: "residentCard",
  props:{
    resident: null,
    responsibles: [],
    medicalCardId: null,
  },
  components: {
    PhonesList,
    ResponsibleList,
    ChronicIllnessList,
    OrderList
  },
  mixins: [loader],
  data: () => ({
    isAdmin: false,
    headers: [
      {text: 'Телефон', align: 'start', sortable: true, value: 'surname',},
      {text: 'Тип', align: 'start', sortable: true, value: 'name',},
      {text: 'Комментарий', align: 'start', sortable: true, value: 'middleName',},
      {text: '', align: 'start', sortable: true, value: 'link',},
    ],
    chronicIllness: null,
    chronicIllnessses: null,
    modal: false,
    modalOrderDate: false,
    modalHospitalDate: false,
    residentChronicIllness: [],
    colors:[
      {text: 'Нет', value: ''},
      {text: 'Красный', value: 'red'},
      {text: 'Зеленый', value: 'green'},
      {text: 'Синий', value: 'primary'},
      {text: 'Желтый', value: 'yellow'},
      {text: 'Оранжевый', value: 'orange'},
      {text: 'Серый', value: 'grey'},
    ],
  }),
  watch:{
    resident(){
      this.getResidentChronicIllness()
      this.chronicIllness = null
    },
    chronicIllness(){
      if (typeof this.chronicIllness == 'string'){
        this.addNewChronicIllness()
      }else if(this.chronicIllness != null){
        this.addChronicIllness()
      }
    }
  },
  methods:{
    addNewChronicIllness(){
      this.httpRequest(
          this.$api.resident.addChronicIllness,{
            id:  this.resident.id,
            chronicIllness: {
              title: this.chronicIllness,
            },
          },
          () => {
            // this.$emit("refreshCard")
            this.getResidentChronicIllness()
            this.getChronicIllness()
          }
      )
    },
    getPhone(){
      this.httpRequest(
          this.$api.resident.getPhone,
          this.resident.id,
          (request) => {
            // this.$emit("refreshCard")
            this.resident.phones = request.data
          }
      )
    },
    addChronicIllness(){
      this.httpRequest(
          this.$api.resident.addChronicIllness,{
            id:  this.resident.id,
            chronicIllness:  this.chronicIllness,
          },
          () => {
            // this.$emit("refreshCard")
            this.getResidentChronicIllness()
            this.getChronicIllness()
          }
      )
    },
    saveResident(){
      this.httpRequest(
          this.$api.resident.update,
          this.resident,
          () => {
            this.$store.dispatch("message/setMessageTop",{
              type: "success",
              text: "Карта сохранена"
            })
            this.$emit("refreshCard")
          }
      )
    },
    getChronicIllness(){
      this.httpRequest(
          this.$api.chronicIllness.getAll,
          null,
          (response) => {
            this.chronicIllnessses = null
            this.chronicIllnessses = response.data
          }
      )
    },
    getResidentChronicIllness(){
      this.httpRequest(
          this.$api.resident.getChronicIllness,
          {
            id: this.resident.id
          },
          (response) => {
            this.residentChronicIllness = response.data
          }
      )
    },
    deleteCard(){
      if (confirm("Удалить карту?")){
        this.httpRequest(
            this.$api.medicalCard.delete,
            this.medicalCardId,
            () => {
              this.$store.dispatch("message/setMessageTop",{
                type: "success",
                text: "Карта удалена"
              })
              this.$emit("deletedCard")
            }
        )
      }
    },
  },
  mounted() {
    this.resident.birthday = this.resident.birthday.slice(0, 10)
    this.resident.orderDate = this.resident.orderDate.slice(0, 10)
    this.getChronicIllness()
    this.getResidentChronicIllness()
    this.isAdmin = this.$store.dispatch("users/hasRoleAuthUser", "ROLE_ADMIN")

  }
}
</script>

<style scoped>

</style>
