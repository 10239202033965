<template>
  <div>
    <div><b>Ответственные:</b>
      <v-btn icon @click="dialog=true">
        <v-icon>add</v-icon>
      </v-btn>
    </div>
    <responsible v-for="responsible in responsibles"
                 :key="responsible.id"
                 :responsible="responsible"
                 :medicalCardId="medicalCardId"
                 @refreshCard="$emit('refreshCard')"
    ></responsible>
    <edit-responsible
        :dialog="dialog"
        :responsibleAll="responsibleAll"
        :medicalCardId="medicalCardId"
        @closeDialog="closeDialog()"
        @refreshCard="$emit('refreshCard')"
    ></edit-responsible>
  </div>
</template>

<script>
import Responsible from './Responsible'
import EditResponsible from "../dialogs/medicalCard/EditResponsible";
import loader from "../../mixins/loader";

export default {
  name: "ResponsibleList",
  props: {
    residentId: null,
    responsibles: [],
    medicalCardId: null,
  },
  data() {
    return {
      dialog: false,
      responsibleAll: [],
    }
  },
  components: {
    Responsible,
    EditResponsible,
  },
  mixins: [loader],
  methods: {
    // refreshCard() {
    //   this.$emit('refreshCard')
    // },
    closeDialog() {
      this.dialog = false;
    },
    getUsers() {
      this.httpRequest(
          this.$api.users.getAll,
          null,
          (response) => {
            this.responsibleAll = response.data
          }
      )
    },

  },
  mounted() {
    this.getUsers()
  }
}
</script>

<style scoped>

</style>
