import instance from './instance'
import authModule from './users/auth'
import usersModule from './users/users'
import rolesModule from './users/role'
import medicalCardModule from './medicalCards/medicalCard'
import residentsModule from './medicalCards/residents'
import phonesModule from './medicalCards/phones'
import chronicIllnessModule from './medicalCards/chronicIllness'
import ordersModule from './order/order'
import servicesModule from './order/service'
import representativesModule from './medicalCards/representatives'
import taskTypesModule from './tasks/taskTypes'
import taskModule from './tasks/task'
import bidModule from './bid/bid'

export default {
    auth: authModule(instance),
    users: usersModule(instance),
    roles: rolesModule(instance),
    medicalCard: medicalCardModule(instance),
    resident: residentsModule(instance),
    phone: phonesModule(instance),
    chronicIllness: chronicIllnessModule(instance),
    orders: ordersModule(instance),
    service: servicesModule(instance),
    representative: representativesModule(instance),
    taskType: taskTypesModule(instance),
    task: taskModule(instance),
    bid: bidModule(instance),
}
