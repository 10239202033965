<template>
  <v-row justify="space-around">
    <v-col cols="auto">
      <v-dialog
          transition="dialog-top-transition"
          max-width="600"
          v-model="dialogs"

      >

        <v-card>
          <v-toolbar
              color="primary"
              dark
          >Добавить Задачу
          </v-toolbar>
          <v-card-text>
            <v-autocomplete
                class="mt-4"
                v-model="task.performer"
                :items="users"
                label="Исполнитель"
                return-object
                :item-text="getUserTitle"
                item-value="id"
            ></v-autocomplete>
            <v-autocomplete
                class="mt-4"
                v-model="task.type"
                :items="taskTypes"
                label="Тип"
                return-object
                item-text="title"
                item-value="id"
            ></v-autocomplete>
            <v-autocomplete
                class="mt-4"
                v-model="task.importance"
                :items="importances"
                label="Важность"
            ></v-autocomplete>
            <v-dialog
                ref="dialog"
                v-model="modal"
                :return-value.sync="task.date"
                persistent
                width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field class="mt-4"
                              v-model="task.date"
                              label="На дату"
                              prepend-icon="event"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                  v-model="task.date"
                  scrollable
                  locale="ru-RU"
                  first-day-of-week=1
              >
                <v-spacer></v-spacer>
                <v-btn
                    text
                    color="primary"
                    @click="modal = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                    text
                    color="primary"
                    @click="$refs.dialog.save(task.date)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
            <v-dialog
                ref="dialogTime"
                v-model="modalTime"
                :return-value.sync="task.time"
                persistent
                width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="task.time"
                    label="Picker in dialog"
                    prepend-icon="schedule"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                ></v-text-field>
              </template>
              <v-time-picker
                  v-if="modalTime"
                  v-model="task.time"
                  full-width
                  format="24hr"
              >
                <v-spacer></v-spacer>
                <v-btn
                    text
                    color="primary"
                    @click="modalTime = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                    text
                    color="primary"
                    @click="$refs.dialogTime.save(task.time)"
                >
                  OK
                </v-btn>
              </v-time-picker>
            </v-dialog>
            <v-textarea class="mt-4"
                        v-model="task.comment"
                        name="comment"
                        label="комментарий"
            ></v-textarea>
          </v-card-text>
          <v-card-actions class="justify-end">

            <v-spacer></v-spacer>
            <v-btn
                text
                @click="closeDialog()"
            >Выйти
            </v-btn>
            <v-btn
                v-if="isSave()"
                text
                dark
                color="primary"
                @click="saveTask()"
            >Сохранить
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import loader from "../../../mixins/loader";

export default {
  name: "EditTask",
  props: {
    dialog: null,
    task: null,
    users:[],
    taskTypes:[],
  },
  data() {
    return {
      modalTime: false,
      modal: false,
      // task: {
      //   performer: null,
      //   type: null,
      //   importance: "LOW_IMPORTANCE",
      //   date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      // },
      importances: [
        {value: "HIGH_IMPORTANCE", text: "Высокая"},
        {value: "MEDIUM_IMPORTANCE", text: "Средняя"},
        {value: "LOW_IMPORTANCE", text: "Низкая"},
      ],

    }
  },
  computed: {
    dialogs: {
      get: function () {
        return this.dialog;
      },
      // сеттер:
      set: function () {
        this.$emit("closeDialog")
      }
    }
  },
  mixins: [loader],
  methods: {
    isSave(){
      if (
          this.task.importance != null &&
          this.task.type != null &&
          this.task.date != null
      ){
        return true
      }
      return false
    },
    closeDialog() {
      this.$emit("refreshCard")
      this.$emit("closeDialog")
    },
    saveTask() {
      this.httpRequest(
          this.$api.task.update,{
            id: this.task.id,
            task: this.task
          },
          () => {
            this.$emit("refreshCard")
            this.$emit("closeDialog")
          }
      )
    },
    getUserTitle(item){
      let userName = item.surname
      if (item.name && item.middleName){
        userName += " " + item.name[0] + "." +  item.middleName[0]
      }
      if (item.position){
        userName += " (" + item.position + ")"
      }
      return  userName
    },
  },
}
</script>

<style scoped>

</style>
