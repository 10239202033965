export default function (instance){
    return {
        getAuthUser(){
            return instance.get('users/current')
        },
        getAll(){
            return instance.get('users')
        },
        getAllDeleted(){
            return instance.get('users/all-deleted')
        },
        save(user){
            return instance.post('users', user)
        },
        getMedicalCards(id){
            return instance.get(`users/${id}/medical-cards`)
        },
        getTasks(payload){
            return instance.post(`users/${payload.id}/tasks`, payload.requestTask)
        },
        getWorkLog(payload){
            return instance.post(`users/${payload.id}/work-log`, payload.filterRequest)
        },
        getEventLog(payload){
            return instance.post(`users/${payload.id}/event-log`, payload.filterRequest)
        },
        searchMedicalCards(request){
            return instance.post(`users/${request.id}/search-medical-cards?page=${request.page}&size=${request.size}`,request.request)
        },
    }
}
