export default function (instance){
    return {
        getAll(){
            return instance.get('services')
        },
        getOne(id){
            return instance.get(`services/${id}`)
        },
        addNewService(service){
            return instance.post('services', service)
        },
        // save(phone){
        //     return instance.post(`orders/${phone.id}`, phone)
        // },
        // update(phone){
        //     return instance.put(`orders/${phone.id}`, phone)
        // },
        deleted(id){
            return instance.delete(`services/${id}`)
        },
    }
}
