export default function (instance){
    return {
        getAll(){
            return instance.get('task-type')
        },
        getOne(id){
            return instance.get(`task-type/${id}`)
        },

    }
}
