
export default {
    state: {
        color_themes: null,
        primary_drawer_model: false,
        primary_drawer_type: 'default (no property)',
        primary_drawer_clipped: false,
        primary_drawer_floating: false,
        primary_drawer_mini: false,
        footer_inset: false,
    },
    mutations: {
        update_settings_color_themes(state, themes){
            state.color_themes = themes;
        },
        update_settings_primary_drawer_model(state, primary_drawer_model){
            state.primary_drawer_model = primary_drawer_model;
        },
        update_settings_primary_drawer_type(state, primary_drawer_type){
            state.primary_drawer_type = primary_drawer_type;
        },
        update_settings_primary_drawer_clipped(state, primary_drawer_clipped){
            state.primary_drawer_clipped = primary_drawer_clipped;
        },
        update_settings_primary_drawer_floating(state, primary_drawer_floating){
            state.primary_drawer_floating = primary_drawer_floating;
        },
        update_settings_primary_drawer_mini(state, primary_drawer_mini){
            state.primary_drawer_mini = primary_drawer_mini;
        },
        update_settings_footer_inset(state, footer_inset){
            state.footer_inset = footer_inset;
        },
    },
    actions: {

    },
    getters:{
        settings_color_themes(state){
            return state.color_themes;
        },
        settings_primary_drawer_model(state){
            return state.primary_drawer_model;
        },
        settings_primary_drawer_type(state){
            return state.primary_drawer_type;
        },
        settings_primary_drawer_clipped(state){
            return state.primary_drawer_clipped;
        },
        settings_primary_drawer_floating(state){
            return state.primary_drawer_floating;
        },
        settings_primary_drawer_mini(state){
            return state.primary_drawer_mini;
        },
        settings_footer_inset(state){
            return state.footer_inset;
        }
    }
}
