
import axios from 'axios';
import settings from '@/settings/settings'

const instance = axios.create({
    baseURL: settings.API.URL_DEFAULT,
    withCredentials: false,
    headers: {
        Authorization: localStorage.getItem('Authorization'),
        accept: 'application/json',
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
        "Access-Control-Allow-Headers": "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
    }
})

export default instance
