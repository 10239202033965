export default function (instance){
    return {
        getAllByStatus(status){
            return instance.get(`bid?status=${status}`)
        },

        deleted(id){
            return instance.delete(`bid/${id}`)
        },
    }
}
