<template>
  <div>

    <div class="login-form-wrap">

      <div class="logo-form">
        <v-icon size="70" dark>person</v-icon>
      </div>
      <h3 class="text-center mb-4">Вход</h3>
      <v-form v-model="valid" class="login-form">
        <div class="form-group">
          <v-text-field
              v-model="email"
              :rules="[rules.required,]"
              label="E-mail"
          ></v-text-field>
        </div>
        <div class="form-group">
          <v-text-field
              v-model="password"
              :append-icon="show ? 'visibility' : 'visibility_off'"
              :rules="[rules.required,]"
              :type="show ? 'text' : 'password'"
              name="input-10-1"
              label="Пароль"
              counter
              @click:append="show = !show"
          ></v-text-field>
        </div>
        <div v-if="errorAuth" style="color: red">Неверный логин или пароль*</div>
        <div class="form-button">
          <v-spacer></v-spacer>
          <v-btn
              :disabled="!valid"
              depressed
              color="primary"
              @click="signIn()"
          >
            Войти
          </v-btn>
        </div>

      </v-form>
    </div>

  </div>
</template>

<script>

import loader from "../mixins/loader"
import userModel from '../model/users'

export default {
  name: "login",
  mixins: [loader],
  data() {
    return {
      valid: false,
      show: false,
      password: '',
      email: '',
      errorAuth: false,
      rules: {
        required: value => !!value || 'Required.',
        counter: value => value.length <= 20 || 'Max 20 characters',
        min: v => v.length >= 6 || 'Минимум 6 символов',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        },
      },

    }
  },
  methods: {

    async signIn() {
      this.httpRequest(
          this.$api.auth.signIn,
          new userModel.AuthenticationRequest(this.email, this.password),
          async () => {
            window.location.replace("/tasks/task-list");
          },
          () => {
            this.errorAuth = true
          },
          true
      )
    }
  }
}
</script>

<style scoped>

.login-form {

}

.form-group {
  margin-bottom: 1rem;
}

.form-button {
  margin: 40px 0 40px 0;
  display: flex;
  flex-direction: row;
  justify-content: right;
}

.login-form-wrap {
  margin: auto;
  height: 500px;
  width: 450px;
  /*border: black 1px solid;*/
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  /*position: relative;*/
  background: #fff;
  border-radius: 10px;
  -webkit-box-shadow: 0px 10px 34px -15px rgb(0 0 0 / 24%);
  -moz-box-shadow: 0px 10px 34px -15px rgba(0, 0, 0, 0.24);
  box-shadow: 0px 10px 34px -15px rgb(0 0 0 / 24%);

  padding: 3rem !important;

}

.logo-form {
  width: 80px;
  height: 80px;
  background: #1089ff;
  border-radius: 50%;
  font-size: 30px;
  margin: 0 auto;
  margin-bottom: 10px;

  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;

  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;

  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

</style>
