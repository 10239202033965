
export default {
    methods:{
        error(error){
            if (error.response?.data?.massage){
                this.$store.dispatch("message/setMessageTop",{
                    type: "error",
                    text: error.response.data?.massage
                })
            }else {
                this.$store.dispatch("message/setMessageTop",{
                    type: "error",
                    text: "Error!"
                })
            }
        },
        httpRequest(http, payload, successHandler, errorHandler, onlyCustomErrorHandler = false, finallyHandler){
            http(payload)
                .then((response)=>{
                    if (successHandler){
                        successHandler(response)
                    }
                }).catch((error) =>{
                    if (!onlyCustomErrorHandler){
                        this.error(error)
                    }
                    if (errorHandler){
                        errorHandler(error)
                    }
            }).finally(()=> {
                if (finallyHandler){
                    finallyHandler()
                }
            })
        }
    }
}
