export default {
    install(Vue){
        Vue.prototype.$load = async (action, errHandler) =>{
            return await action
                .catch((error)=>{
                    if (errHandler){
                        errHandler()
                    }else {
                        console.log(error)
                    }
                })
        }
    }
}
