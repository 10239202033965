<template>
  <div class="d-flex flex-column">
    <div class="d-flex flex-row flex-wrap">
      <div class="mt-5 ml-10 mr-5 mb-5" >
        <v-text-field
            label="Фамилия"
            hide-details="auto"
            v-model="representative.surname"
        ></v-text-field>
        <v-text-field
            label="Имя"
            v-model="representative.name"
            hide-details="auto"
        ></v-text-field>
        <v-text-field
            label="Отчество"
            v-model="representative.middleName"
            hide-details="auto"
        ></v-text-field>
        <v-text-field
            label="Отношение"
            v-model="representative.relations"
            hide-details="auto"
        ></v-text-field>
        <v-dialog
            ref="dialog"
            v-model="modal"
            :return-value.sync="representative.birthday"
            persistent
            width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field class="mt-4"
                          v-model="representative.birthday"
                          label="Дата рождения"
                          prepend-icon="event"
                          readonly
                          v-bind="attrs"
                          v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
              v-model="representative.birthday"
              scrollable
              locale="ru-RU"
              first-day-of-week=1
          >
            <v-spacer></v-spacer>
            <v-btn
                text
                color="primary"
                @click="modal = false"
            >
              Cancel
            </v-btn>
            <v-btn
                text
                color="primary"
                @click="$refs.dialog.save(representative.birthday)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>
        <v-radio-group
            v-model="representative.male"
            row
        >
          <v-radio
              label="М"
              value="MAN"
          ></v-radio>
          <v-radio
              label="Ж"
              value="WOMAN"
          ></v-radio>
        </v-radio-group>
        <v-card class="pa-2">
          <representative-phones-list
              :representId="representative.id"
              @refreshCard="$emit('refreshCard')"
          ></representative-phones-list>
        </v-card>
      </div>
      <div class="mt-5 ml-10 mr-5 mb-5">
        <v-text-field
            label="Область"
            v-model="representative.region"
            hide-details="auto"
        ></v-text-field>
        <v-text-field
            label="Город"
            v-model="representative.city"
            hide-details="auto"
        ></v-text-field>
        <v-textarea class="mt-3"
                    name="input-7-1"
                    label="Адрес"
                    rows="3"
                    v-model="representative.address"
                    hint="Введите адрес"
        ></v-textarea>
      </div>
    </div>
    <div class="d-flex justify-end mr-6">
      <v-btn v-if="isAdmin" style="margin-right: 20px"
          color="error"
          @click="deleteRepresent()"
      >
        Удалить
      </v-btn>
      <v-btn
          color="primary"
          @click="updateRepresent()"
      >
        Сохранить
      </v-btn>
    </div>
  </div>
</template>

<script>
import RepresentativePhonesList from "./RepresentativePhonesList";
import loader from "../../mixins/loader";
// import RepresentativeList from "./RepresentativeList";

export default {
  name: "Representative",
  components: {
    RepresentativePhonesList,
  },
  props: {
    representative: null
  },
  data() {
    return {
      isAdmin: false,
      dialog: false,
      modal: false,
    }
  },
  watch:{
    "representative"(){
      this.representative.birthday = this.representative.birthday.slice(0, 10)
    }
  },
  mixins: [loader],
  methods:{
    updateRepresent(){
      this.httpRequest(
          this.$api.representative.update,
          this.representative,
          () => {
            this.$store.dispatch("message/setMessageTop",{
              type: "success",
              text: "Карта сохранена"
            })
            this.$emit("refreshCard")
          }
      )
    },
    deleteRepresent(){
      if (confirm("Удалить представителя?")){
        this.httpRequest(
            this.$api.representative.delete,
            this.representative,
            () => {
              this.$store.dispatch("message/setMessageTop",{
                type: "success",
                text: "Представитель удален"
              })
              this.$emit("refreshCard")
            }
        )
      }
    },
    closeDialog(){
      this.dialog = false;
    }
  },
  async mounted() {
    this.isAdmin = await this.$store.dispatch("users/hasRoleAuthUser", "ROLE_ADMIN")
  }
}
</script>

<style scoped>
.cursor{
  cursor: pointer;
}
</style>
