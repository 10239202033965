export default function (instance){
    return {
        getAll(){
            return instance.get('representatives')
        },
        getOne(id){
            return instance.get(`representatives/${id}`)
        },
        // save(resident){
        //     return instance.post(`residents/${resident.id}`, resident)
        // },
        getPhones(id){
            return instance.get(`representatives/${id}/phones`)
        },
        update(represent){
            return instance.put(`representatives/${represent.id}`, represent)
        },
        addPhone(request){
            return instance.post(`representatives/${request.id}/phones`, request.phone)
        },
        delete(represent){
            return instance.delete(`representatives/${represent.id}`)
        },

    }
}
