<template>
  <div class="comment-wrap">
    <div class="mr-2">
      <b class="text-no-wrap"> {{ getName(comment.user) }}</b>
      <div><b>{{ comment.date | date }}</b></div>
      <div><b>{{ comment.time }} </b></div>
    </div>

<!--    <div class="mr-2">-->

<!--    </div>-->
    <div>{{ comment.comment }}</div>
  </div>
</template>

<script>
export default {
  name: "Comment",
  props: {
    comment: null,
  },

  filters: {
    date(date) {
      return date.slice(0, 10)
    }
  },
  methods: {
    getName(user) {
      return user.surname + " " + user.name[0] + "." + user.middleName[0]
    }
  },
}
</script>

<style scoped>
.theme--dark .comment-wrap {
  display: flex;
  flex-direction: row;
  justify-content: start;
  padding: 5px 10px 5px 10px;
  margin: 5px 10px 10px 10px;
  background-color: #1a1a1a;
  border-radius: 10px;
}

.theme--light .comment-wrap {
  display: flex;
  flex-direction: row;
  justify-content: start;
  padding: 5px 10px 5px 10px;
  margin: 5px 10px 10px 10px;
  background-color: #ecf3ff;
  border-radius: 10px;
}

</style>
