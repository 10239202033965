<template>
  <v-row justify="space-around">
    <v-col cols="auto">
      <v-dialog
          transition="dialog-top-transition"
          max-width="600"
          v-model="dialogs"

      >

        <v-card>
          <v-toolbar
              color="primary"
              dark
          >Добавить Комментарий
          </v-toolbar>
          <v-card-text>
            <v-textarea class="mt-4"
                        v-model="comment"
                        name="comment"
                        label="Введите комментарий"
            ></v-textarea>
          </v-card-text>
          <v-card-actions class="justify-end">

            <v-spacer></v-spacer>
            <v-btn
                text
                @click="closeDialog()"
            >Выйти
            </v-btn>
            <v-btn
                v-if="isSave()"
                text
                dark
                color="primary"
                @click="saveComment()"
            >Сохранить
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import loader from "../../../mixins/loader";

export default {
  name: "NewComment",
  props: {
    dialog: null,
    medicalCardId: null,
  },
  data() {
    return {
      comment: "",
    }
  },
  computed: {
    dialogs: {
      get: function () {
        return this.dialog;
      },
      // сеттер:
      set: function () {
        this.$emit("closeDialog")
      }
    }
  },

  mixins: [loader],
  methods: {
    closeDialog() {
      this.$emit("refreshComments")
      this.$emit("closeDialog")
    },
    isSave() {
      if (this.medicalCardId != null && this.comment.trim()) {
        return true
      }
      return false
    },
    saveComment() {
      this.httpRequest(
          this.$api.medicalCard.addComment,{
            id: this.medicalCardId,
            comment: {
              comment: this.comment
            }
          },
          () => {
            this.$emit("closeDialog")
            this.$emit("refreshComments")
          }
      )
    },
  },
}
</script>

<style scoped>

</style>
