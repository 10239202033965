<template>
  <div class="d-flex flex-row">
    <div class="d-flex flex-nowrap">
    <div class="mt-1">
      {{ chronicIllness.title }}
    </div>
    <div
        class="d-inline"
        @click="deleteResponsible()"
    >
      <v-btn icon><v-icon>close</v-icon></v-btn>
    </div>
    </div>
<!--    <edit-phones-->
<!--      :dialog="dialog"-->
<!--      :phone="responsible"-->
<!--      @closeDialog="closeDialog()"-->
<!--      @refreshCard="$emit('refreshCard')"-->
<!--    ></edit-phones>-->
  </div>
</template>

<script>
// import EditPhones from "../dialogs/medicalCard/EditPhones";

import loader from "../../mixins/loader";

export default {
  name: "ChronicIllness",
  components: {
    // EditPhones,
  },
  props: {
    chronicIllness: null,
    residentId: null
  },
  mixins: [loader],

  // data() {
  //   return {
  //     dialog: false
  //   }
  // },
  methods:{
    closeDialog(){
      this.dialog = false;
    },
    deleteResponsible(){
      this.httpRequest(
          this.$api.resident.deleteChronicIllness,
          {
            id: this.residentId,
            chronicIllnessId: this.chronicIllness.id
          },
          () => {
            this.$emit("refreshCard")
          }
      )
    },
  }
}
</script>

<style scoped>
.cursor{
  cursor: pointer;
}
</style>
