var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"counter":10,"label":"Имя","required":""},model:{value:(_vm.user.name),callback:function ($$v) {_vm.$set(_vm.user, "name", $$v)},expression:"user.name"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"counter":10,"label":"Отчество","required":""},model:{value:(_vm.user.middleName),callback:function ($$v) {_vm.$set(_vm.user, "middleName", $$v)},expression:"user.middleName"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"counter":10,"label":"Фамилия","required":""},model:{value:(_vm.user.surname),callback:function ($$v) {_vm.$set(_vm.user, "surname", $$v)},expression:"user.surname"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"label":"E-mail","required":""},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field-integer',{attrs:{"label":"Телефон","required":"","properties":{
            readonly: false,
            disabled: false,
            outlined: false,
            clearable: true,
            placeholder: '',
          },"options":{
            inputMask: '#(###)###-##-##',
            outputMask: '###########',
            empty: null,
            applyAfter: false,
          }},model:{value:(_vm.user.phone),callback:function ($$v) {_vm.$set(_vm.user, "phone", $$v)},expression:"user.phone"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-select',{attrs:{"items":_vm.roleAll,"label":"Роль","multiple":"","persistent-hint":"","return-object":"","item-text":"description","item-value":"id"},model:{value:(_vm.user.role),callback:function ($$v) {_vm.$set(_vm.user, "role", $$v)},expression:"user.role"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"label":"Пароль","required":""},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"counter":10,"label":"Должность","required":""},model:{value:(_vm.user.position),callback:function ($$v) {_vm.$set(_vm.user, "position", $$v)},expression:"user.position"}})],1)],1),_c('v-row',[_c('v-checkbox',{attrs:{"label":_vm.labelCheckbox},model:{value:(_vm.checkbox),callback:function ($$v) {_vm.checkbox=$$v},expression:"checkbox"}})],1),_c('v-row',[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.save()}}},[_vm._v("Сохранить ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }