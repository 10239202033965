<template>
  <div class="d-flex flex-row">
    <div class="d-flex flex-nowrap">
      <div class="mt-1">
        {{ getUserName(responsible) }}
      </div>
      <div
          class="d-inline"
          @click="deleteResponsible()"
      >
        <v-btn icon>
          <v-icon>close</v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
// import EditPhones from "../dialogs/medicalCard/EditPhones";

import loader from "../../mixins/loader";

export default {
  name: "Responsible",
  components: {
    // EditPhones,
  },
  props: {
    responsible: null,
    medicalCardId: null
  },
  mixins: [loader],

  // data() {
  //   return {
  //     dialog: false
  //   }
  // },
  methods: {
    closeDialog() {
      this.dialog = false;
    },
    getUserName(user){
      let userName = user.surname
      if (user.name && user.middleName){
        userName += " " + user.name[0] + "." +  user.middleName[0]
      }
      if (user.position){
        userName += " (" + user.position + ")"
      }
      return  userName
    },
    deleteResponsible() {
      this.httpRequest(
          this.$api.medicalCard.deleteResponsible,
          {
            id: this.medicalCardId,
            responsible: this.responsible
          },
          () => {
            this.$emit("refreshCard")
            this.$emit("closeDialog")
          }
      )
    },
  }
}
</script>

<style scoped>
.cursor {
  cursor: pointer;
}
</style>
