<template>
  <div>
<!--    <v-card class="d-flex flex-column">-->
<!--    d-flex flex-sm-column flex-lg-row justify-space-between ml-5 mr-5-->
      <div class="card-wrap">
        <v-card class="resident-card flex-grow-1">
          <div style="display: flex; justify-content: space-between; margin: 0 20px 0 20px">
            <v-btn

                icon
                @click="$router.go(-1)"
            >
              <v-icon>reply</v-icon>
            </v-btn>
            <v-btn
                icon
                @click="copyText()"
            >
              <v-icon>content_copy</v-icon>
            </v-btn>
          </div>

          <resident-card
              :resident="medicalCard.resident"
              :responsibles="medicalCard.responsible"
              :medicalCardId="medicalCard.id"
              @refreshCard="refreshCard()"
              @deletedCard="deleteCard()"
              @refreshResponsible="refreshResponsible()"
          ></resident-card>
        </v-card>
        <v-card class="represent-card flex-grow-1 ">
          <representative-list
              :resident="medicalCard.resident"
              @refreshCardR="refreshCardR()"
              @refreshCard="refreshCard()"
          ></representative-list>
          <v-divider></v-divider>
          <div class="mt-4 ml-4 mr-4">
            <div class="d-flex justify-center">
              <b class="pt-1">Общая информация</b>
              <v-btn
                  icon
                  @click="medicalCardCommentDialog = true"
              >
                <v-icon>settings</v-icon>
              </v-btn>
            </div>
            {{ medicalCard.info }}

            <medical-card-comment
                :dialog="medicalCardCommentDialog"
                :medicalCard="medicalCard"
                @closeDialog="medicalCardCommentDialog = false"
                @refreshCard="refreshCard"
            ></medical-card-comment>

          </div>

        </v-card>
      </div>
    <div>

    </div>
      <div class="d-flex flex-sm-column flex-wrap flex-lg-row justify-space-between ml-1 mt-4">
        <div class="task-list mt-4" >
          <v-card>
            <task-list
                :medicalCardId="medicalCard.id"
                @refreshComment="getComments"
            ></task-list>
          </v-card>
        </div>
        <div class="comment-list  mt-4">
          <v-card>
            <comment-list
                :medicalCardId="medicalCard.id"
                :comments="comments"
                @refreshComments="getComments"
            ></comment-list>
          </v-card>
        </div>

      </div>
<!--    </v-card>-->
  </div>
</template>

<script>
import loader from "../../mixins/loader"
import ResidentCard from "../../components/medicalCard/ResidentCard"
import CommentList from "../../components/medicalCard/CommentList"
import TaskList from "../../components/medicalCard/TaskList"
import RepresentativeList from "../../components/medicalCard/RepresentativeList"
import MedicalCardComment from "../../components/dialogs/medicalCard/MedicalCardComment"
import {mapState} from 'vuex'

export default {
  name: "MedicalCard",
  components: {
    ResidentCard,
    RepresentativeList,
    TaskList,
    CommentList,
    MedicalCardComment
  },

  computed: {
    ...mapState('medicalCards', [
      'medicalCard',
    ]),
  },

  data() {
    return {
      medicalCardCommentDialog: false,
      comments: [],
      chronicIllnessses: null,
      residentPhones: null,
      // representativeList: null,
    }
  },
  watch: {},
  mixins: [loader],
  methods: {
    async copyText() {
      var text = "";
      text += "Номер: " + this.medicalCard.id + "\n"
      text += "Дата обращения: "  + this.medicalCard.resident.orderDate + "\n"
      text += "Резидент: " + this.medicalCard.resident.surname + " " + this.medicalCard.resident.name + " " + this.medicalCard.resident.middleName + " " + "\n"
      text += "ДР: " + this.medicalCard.resident.birthday + "\n"
      text += "Пол: " + this.getMale(this.medicalCard.resident.male) + "\n"
      text += "Город: " + this.medicalCard.resident.city + "\n"
      text += "Адрес: " + this.medicalCard.resident.address + "\n"
      text += "Согласие: " + this.getagreement(this.medicalCard.resident.agreement) + "\n"


      text += "Хронические заболевания: " + "\n"
      await this.getChronicIllness()
      this.chronicIllnessses.forEach((item)=>{
        text += " - " + item.title + "\n"
      })

      text += "Телефоны:: " + "\n"
      await this.getResidentPhones()
      this.residentPhones.forEach((item)=>{
        text += " - " + item.phone + "\n"
      })
      text +=  "\n"

      this.medicalCard.resident.representatives.forEach((item)=>{
        text += "Представитель: "  + "\n"
        text += "   ФИО " + item.surname + " " + item.name + " " + item.middleName + "\n"
        text += "   Пол " + this.getMale(item.male)  + "\n"
        text += "   Город: " + item.city + "\n"
        text += "   Адрес: " + item.address + "\n"
        text += "   Телефоны:: " + "\n"
        item.phones.forEach((p)=>{
          text += "    - " + p.phone + "\n"
        })
        text += "\n"
      })
      text += "Комментарий: " + this.medicalCard.info + "\n"

      var temp = document.createElement("textarea");
      temp.setAttribute('type', 'text')
      temp.value = text //.select();
      var body = document.body
      body.appendChild(temp)
      temp.select();
      document.execCommand("copy");
      temp.remove();
      // navigator.clipboard.writeText(text)
    },
    getagreement(agreement){
      if (agreement){
        return "ДА"
      }
      return "НЕТ"
    },
    getMale(male){
      if (male == "MAN"){
        return "М"
      }else if (male == "WOMAN"){
        return "Ж"
      }
      return ""
    },

    getResidentPhones(){
      return  new Promise((resolve)=>{
        this.httpRequest(
            this.$api.resident.getPhone,
            this.medicalCard.resident.id,
            (response) => {
              this.residentPhones = null
              this.residentPhones = response.data
              resolve(response.data)
            }
        )
      })
    },
    getChronicIllness(){
      return  new Promise((resolve)=>{
        this.httpRequest(
            this.$api.resident.getChronicIllness,
            {
              id: this.medicalCard.resident.id
            },
            (response) => {
              this.chronicIllnessses = null
              this.chronicIllnessses = response.data
              resolve(response.data)
            }
        )
      })
    },
    cardEdit() {

    },
    refreshResponsible(){
      this.httpRequest(
          this.$api.medicalCard.getResponsible,
          this.medicalCard.id,
          async (response) => {
            this.medicalCard.responsible = response.data
          }
      )
    },
    getComments() {
      this.httpRequest(
          this.$api.medicalCard.getComments,
          this.medicalCard.id,
          async (response) => {
            this.comments = response.data
          }
      )
    },
    refreshCard() {
      this.httpRequest(
          this.$api.medicalCard.getOne,
          this.medicalCard.id,
          async (response) => {
            await this.$store.dispatch("medicalCards/setMedicalCard", response.data)
          }
      )
    },
    refreshCardR() {
      console.log("sdfsdfsd")
      this.httpRequest(
          this.$api.resident.update,
          this.medicalCard.resident,
          () => {
            this.$store.dispatch("message/setMessageTop",{
              type: "success",
              text: "Карта сохранена"
            })
            this.httpRequest(
                this.$api.medicalCard.getOne,
                this.medicalCard.id,
                async (response) => {
                  await this.$store.dispatch("medicalCards/setMedicalCard", response.data)
                }
            )
          }
      )

    },
    async deleteCard() {
      await this.$router.push('/medical-card/medical-card-list')
    },
  },
  async mounted() {
    this.getComments()
  },
  created() {
  },

}
</script>

<style lang="css" scoped>
.card-wrap{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
.represent-card {
  flex-basis: 40%;
}
.resident-card {
  flex-basis: 60%;
}

.task-list {
  display: flex;
  max-width: 100%;
  flex-basis: 60%;
}

.comment-list {
  display: flex;
  flex-basis: 40%;
}
</style>
