<template>
  <div>
    <v-expansion-panels>
      <v-expansion-panel>
        <v-expansion-panel-header>
          Фильтры
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <div class="filter-wrap">
            <div v-if="isAdmin || isSeniorManager">
              <div>
                <v-autocomplete
                    v-model="usersId"
                    :items="users"
                    multiple
                    label="Исполнитель"
                    :item-text="getName"
                    item-value="id"
                ></v-autocomplete>
              </div>
            </div>
            <div>
              <v-dialog class="mb-4"
                  ref="dialog"
                  v-model="modal"
                  :return-value.sync="dateStart"
                  persistent
                  width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field class="mt-4"
                                v-model="dateStart"
                                label="С"
                                prepend-icon="event"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="dateStart"
                    scrollable
                    locale="ru-RU"
                    first-day-of-week=1
                >
                  <v-spacer></v-spacer>
                  <v-btn
                      text
                      color="primary"
                      @click="modal = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                      text
                      color="primary"
                      @click="$refs.dialog.save(dateStart)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-dialog>

            </div>
            <div>
              <v-dialog
                  ref="dialogEnd"
                  v-model="modalEnd"
                  :return-value.sync="dateEnd"
                  persistent
                  width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field class="mt-4"
                                v-model="dateEnd"
                                label="по"
                                prepend-icon="event"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="dateEnd"
                    scrollable
                    locale="ru-RU"
                    first-day-of-week=1
                >
                  <v-spacer></v-spacer>
                  <v-btn
                      text
                      color="primary"
                      @click="modalEnd = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                      text
                      color="primary"
                      @click="$refs.dialogEnd.save(dateEnd)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-dialog>

            </div>
            <div >
              <div>
                <v-btn
                    color="primary"
                    @click="getTasks()"
                >
                  Поиск
                </v-btn>
              </div>
            </div>

          </div>


        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-data-table
        :headers="headers"
        :items="taskList"
        :items-per-page="15"
        class="elevation-1"
        :footer-props="{
            itemsPerPageText:'Элементов на странице',
        }"
    >
      <template v-slot:item.medicalCard="{ item }">
        <div>{{ item.medicalCardId }}</div>
      </template>
      <template v-slot:item.creator="{ item }">
        <div>{{ getUserName(item.creator) }}</div>
      </template>
      <template v-slot:item.performer="{ item }">
        <div>{{ getUserName(item.performer) }}</div>
      </template>
      <template v-slot:item.type="{ item }">
        <div>{{ item.type.title }}</div>
      </template>
      <template v-slot:item.status="{ item }">
        <div>{{ getStatus(item.status) }}</div>
      </template>
      <template v-slot:item.link="{ item }">
        <v-btn icon @click="cardEdit(item.medicalCardId)">
          <v-icon>done_outline</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import loader from "../../mixins/loader";

export default {
  name: "TasksList",
  data() {
    return {
      isAdmin: false,
      isSeniorManager: false,
      modal: false,
      modalEnd: false,
      users: [],
      usersId: [],
      dateStart: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      dateEnd: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),

      headers: [
        {text: 'N', align: 'start', sortable: true, value: 'id',},
        {text: 'Карта №', align: 'start', sortable: true, value: 'medicalCard',},
        {text: 'Создал', align: 'start', sortable: true, value: 'creator',},
        {text: 'Исполнитель', align: 'start', sortable: true, value: 'performer',},
        {text: 'Дата', align: 'start', sortable: true, value: 'date',},
        {text: 'Время', align: 'start', sortable: true, value: 'time',},
        {text: 'Тип', align: 'start', sortable: true, value: 'type',},
        {text: 'Статус', align: 'start', sortable: true, value: 'status',},
        {text: '', align: 'start', sortable: true, value: 'link',},
      ],
      taskList: [],
    }
  },
  mixins: [loader],
  methods: {
    getStatus(status) {
      if (status === "ACTIVE") {
        return "В работе"
      }
      if (status === "NOT_ACTIVE") {
        return "Исполнено"
      }
    },
    cardEdit(id) {
      this.httpRequest(
          this.$api.medicalCard.getOne,
          id,
          async (response) => {
            await this.$store.dispatch("medicalCards/setMedicalCard", response.data)
            await this.$router.push('/medical-card/medical-card')
          }
      )
    },
    getUserName(item) {
      let userName = item.surname
      if (item.name && item.middleName){
        userName += " " + item.name[0] + "." +  item.middleName[0]
      }
      if (item.position){
        userName += " (" + item.position + ")"
      }
      return  userName
    },
    getTasks() {
      this.httpRequest(
          this.$api.task.getByUsersAndDates,
          {
            usersId: this.usersId,
            dateStart: this.dateStart,
            dateEnd: this.dateEnd
          },
          async (response) => {
            this.taskList = response.data
          }
      )
    },
    getUsers() {
      this.httpRequest(
          this.$api.users.getAll,
          null,
          async (response) => {
            this.users = response.data
            const user = await this.$store.dispatch("users/getAuthUser")
            for (let i = 0; i < this.users.length; i++) {
              if (this.users[i].id === user.id) {
                this.usersId.push(this.users[i].id)
                break;
              }
            }

            if (this.users) {
              this.getTasks()
            }

          }
      )
    },
    getName(item) {
      return item.surname + " " + item.name + " " + item.middleName
    },
  },
  async mounted() {
    this.getUsers()
    this.isAdmin = await this.$store.dispatch("users/hasRoleAuthUser", "ROLE_ADMIN")
    this.isSeniorManager = await this.$store.dispatch("users/hasRoleAuthUser", "ROLE_SENIOR_MANGER")
  }
}
</script>

<style scoped>
.filter-wrap{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
}
</style>
