<template>
  <div></div>
</template>

<script>
export default {
  name: "Home",
  mounted() {
    window.location.href = "/medical-card/medical-card-list"
  }
}
</script>

<style scoped>

</style>
