var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{attrs:{"justify":"space-around"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-dialog',{attrs:{"transition":"dialog-top-transition","max-width":"600"},model:{value:(_vm.dialogs),callback:function ($$v) {_vm.dialogs=$$v},expression:"dialogs"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":"primary","dark":""}},[_vm._v("Редактировать телефон ")]),_c('v-card-text',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field-integer',{attrs:{"label":"Телефон","required":"","properties":{
                      readonly: false,
                      disabled: false,
                      outlined: false,
                      clearable: true,
                      placeholder: '',
                    },"options":{
                      inputMask: '#(###)###-##-##',
                      outputMask: '###########',
                      empty: null,
                      applyAfter: false,
                    }},model:{value:(_vm.phone.phone),callback:function ($$v) {_vm.$set(_vm.phone, "phone", $$v)},expression:"phone.phone"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"label":"Комментарий","hide-details":"auto"},model:{value:(_vm.phone.comment),callback:function ($$v) {_vm.$set(_vm.phone, "comment", $$v)},expression:"phone.comment"}})],1)],1),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"text":"","color":"error"},on:{"click":function($event){return _vm.deletedPhone()}}},[_vm._v("Удалить ")]),_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.closeDialog()}}},[_vm._v("Выйти ")]),_c('v-btn',{attrs:{"text":"","dark":"","color":"primary"},on:{"click":function($event){return _vm.savePhone()}}},[_vm._v("Сохранить ")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }